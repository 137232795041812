import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { headingSizes } from '../../styles/headings';

const accordionStyle = {
  width: '100%',
  maxWidth: '50rem',
  textAlign: 'left',
} as const;

interface Faq {
  question: string;
  answer: ReactNode[];
}

export interface Props {
  faqs: Faq[];
}

export default function Faqs(props: Props) {
  const { faqs } = props;

  return (
    <Accordion sx={accordionStyle} allowMultiple>
      {faqs.map((faq, index) => (
        <AccordionItem key={`faq_${index}`}>
          {({ isExpanded }) => (
            <>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left" sx={{ ...headingSizes['h-lg'], my: 1 }}>
                    {faq.question}
                  </Box>
                  <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} size="2x" />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4} pt={0}>
                {faq.answer}{' '}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
}
