import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger as ChakraPopoverTrigger,
} from '@chakra-ui/react';
import { faTicket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import { AUTH_MODAL_PAGES, SUBSCRIBE_MODAL_PAGES } from '../../constants/enums';
import {
  AUTH_MODAL_LOGIN_VIEWED,
  AUTH_MODAL_OPENED,
  AUTH_MODAL_SIGN_UP_VIEWED,
  SUBSCRIBE_MODAL_DETAILS_VIEWED,
  SUBSCRIBE_MODAL_OPENED,
} from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { openAuthModal, openSubscribeModal } from '../../store/modalsSlice';
import { getInitials } from '../../utils/initials';
import analyticsEvent from '../../utils/logEvent';
import SubscribeCTA from '../ctas/SubscribeCTA';
import NavLink, { NavigationItem } from './NavLink';

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> = ChakraPopoverTrigger;

const buttonStyle = {
  height: { base: 9, '2xl': 10 },
  fontSize: { base: '0.875rem', '2xl': '1rem' },
} as const;

const avatarButtonStyle = {
  border: '0.125rem solid',
  width: { base: 10, '2xl': 12 },
  height: { base: 10, '2xl': 12 },
} as const;

interface Props {
  MainLinks: Array<NavigationItem>;
  UserLinks: Array<NavigationItem>;
}

export default function BackstageNavDesktop(props: Props) {
  const { MainLinks, UserLinks } = props;
  const dispatch: any = useAppDispatch();
  const { user } = useTypedSelector((state) => state);

  const isLoggedIn = !!user.firebaseUid && !!user.id;
  const isVip = !!user.isVip;
  const isLoading = user.authLoading || (user.firebaseUid && !user.id);
  const initials = getInitials(user.firstName || '', user.lastName || '');

  const t = useTranslations('Global');

  const desktopNavStyle = {
    flex: 1,
    marginLeft: {
      base: 0,
      md: isVip ? 'calc(50% - 16rem)' : 'calc(50% - 18rem)',
      xl: 'calc(50% - 16.5rem)',
    },
  } as const;

  const vipBadgeStyle = {
    opacity: '1 !important',
    cursor: 'auto !important',
  } as const;

  const eventOrigin = 'navigation_desktop';

  function onClickOpenAuthModal(page: AUTH_MODAL_PAGES): void {
    analyticsEvent(AUTH_MODAL_OPENED, { origin_component: eventOrigin });
    analyticsEvent(
      page === AUTH_MODAL_PAGES.LOGIN ? AUTH_MODAL_LOGIN_VIEWED : AUTH_MODAL_SIGN_UP_VIEWED,
      { origin_component: eventOrigin },
    );
    dispatch(openAuthModal(page));
  }

  function onClickOpenSubscribeModal(): void {
    analyticsEvent(SUBSCRIBE_MODAL_OPENED, { origin_component: eventOrigin });
    analyticsEvent(SUBSCRIBE_MODAL_DETAILS_VIEWED, { origin_component: eventOrigin });
    dispatch(openSubscribeModal(SUBSCRIBE_MODAL_PAGES.DETAILS));
  }

  return (
    <>
      <HStack
        as={'nav'}
        sx={desktopNavStyle}
        spacing={{ base: 4, lg: 12 }}
        data-test-id="desktop-nav"
      >
        {MainLinks.map((link) => (
          <NavLink key={link.title} link={link} />
        ))}
      </HStack>

      {!isLoading && (
        <HStack spacing={4}>
          {isLoggedIn ? (
            <>
              {/* User menu */}
              {isVip && (
                <Button sx={vipBadgeStyle} colorScheme="black" disabled size="sm">
                  {t('vip')}
                </Button>
              )}
              <Popover>
                <PopoverTrigger>
                  <Button
                    sx={avatarButtonStyle}
                    rounded={'full'}
                    variant={'link'}
                    colorScheme="black"
                    data-test-id="desktop-nav-user-button"
                  >
                    {initials}
                  </Button>
                </PopoverTrigger>
                <PopoverContent top={-2}>
                  <PopoverBody>
                    <Box mb={isVip ? 0 : 6} data-test-id="desktop-nav-user-menu">
                      {UserLinks.map((link) => (
                        <NavLink key={link.title} link={link} />
                      ))}
                    </Box>
                    {!isVip && <SubscribeCTA />}
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              {!isVip && (
                <Button
                  sx={buttonStyle}
                  leftIcon={<FontAwesomeIcon icon={faTicket} />}
                  colorScheme="black"
                  onClick={onClickOpenSubscribeModal}
                >
                  {t('subscribeButton')}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                sx={buttonStyle}
                variant="unstyled"
                data-test-id="nav-login-button"
                onClick={() => onClickOpenAuthModal(AUTH_MODAL_PAGES.LOGIN)}
              >
                {t('nav.login')}
              </Button>
              <Button onClick={() => onClickOpenAuthModal(AUTH_MODAL_PAGES.SIGN_UP)}>
                {t('nav.signUp')}
              </Button>
            </>
          )}
        </HStack>
      )}
    </>
  );
}
