export const headingSizes = {
  'h-4xl': {
    fontSize: { base: '3.25rem', xl: '5rem', '2xl': '5.75rem' },
    fontWeight: 700,
    lineHeight: '100%',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  'h-3xl': {
    fontSize: { base: '2.625rem', xl: '3.25rem', '2xl': '3.75rem' },
    fontWeight: 700,
    lineHeight: { base: '100%', '2xl': '110%' },
    textTransform: 'uppercase',
    marginBottom: 6,
  },
  'h-2xl': {
    fontSize: { base: '2.25rem', xl: '2.75rem', '2xl': '3.25rem' },
    fontWeight: 700,
    lineHeight: { base: '120%', '2xl': '110%' },
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  'h-xl': {
    fontSize: { base: '1.875rem', xl: '2rem', '2xl': '2.25rem' },
    fontWeight: 700,
    lineHeight: { base: '120%', '2xl': '120%' },
    textTransform: 'uppercase',
    marginBottom: 3,
  },
  'h-lg': {
    fontSize: { base: '1.5rem', xl: '1.625rem', '2xl': '1.875rem' },
    fontWeight: 700,
    lineHeight: { base: '120%', '2xl': '120%' },
    textTransform: 'uppercase',
    marginBottom: 3,
  },
  'h-md': {
    fontSize: { base: '1.175rem', xl: '1.2rem', '2xl': '1.25rem' },
    fontWeight: 700,
    lineHeight: '120%',
    textTransform: 'uppercase',
    marginBottom: 3,
  },
  'h-sm': {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '120%',
    textTransform: 'uppercase',
    marginBottom: 2,
  },
  'h-xs': {
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '120%',
    textTransform: 'uppercase',
    marginBottom: 2,
  },
};
