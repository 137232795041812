import { render } from 'storyblok-rich-text-react-renderer';
import { RichTextConfig } from '../../config/storyblokRichtext';
import { IStoryblokFaqs } from '../../types/storyblokComponents';
import Faqs from '../ui/Faqs';

interface Props {
  blok: IStoryblokFaqs;
}

export default function StoryblokFaqs(props: Props) {
  const { blok } = props;

  const faqs = blok.faqs.map((faq) => {
    return { question: faq.question, answer: render(faq.answer, RichTextConfig) };
  });

  return <Faqs faqs={faqs} />;
}
