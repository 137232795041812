import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { useEffect } from 'react';
import { VIDEO_MODAL_CLOSED } from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { clearModalsSlice } from '../../store/modalsSlice';
import analyticsEvent from '../../utils/logEvent';
import VideoPlayer from '../ui/VideoPlayer';

export default function VideoModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals, videoModal } = useTypedSelector((state) => state);
  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (modals.videoModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.videoModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    analyticsEvent(VIDEO_MODAL_CLOSED);
    dispatch(clearModalsSlice());
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={'4xl'}>
      <ModalOverlay />
      <ModalContent data-test-id="video-modal-content" overflow="hidden" background="transparent">
        <ModalCloseButton zIndex={2} data-test-id="video-modal-close-button" />
        <Box data-test-id="video-container">
          <VideoPlayer
            url={videoModal.url}
            autoplay={true}
            loop={false}
            useNativeControls={true}
            analyticsEventsData={{ origin_component: 'video_modal' }}
          />
        </Box>
      </ModalContent>
    </Modal>
  );
}
