import { Box, Flex } from '@chakra-ui/react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { SIZES } from '../../constants/enums';
import { getAlignedContainerStyle } from '../../styles/global';
import { IStoryblokRow } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokRow;
}

export default function StoryblokRow(props: Props) {
  const { blok } = props;

  const rowStyles = {
    flexDirection: { base: blok.reverse_for_mobile ? 'column-reverse' : 'column', md: 'row' },
    width: '100%',
    gap: {
      base: 6,
      md: 24 / blok.columns.length,
    },
  } as const;

  return (
    <Flex sx={rowStyles} {...storyblokEditable(blok)} data-test-id="row">
      {blok.columns?.map((column) => {
        const columnStyles = {
          flex: column.width === SIZES.MD ? 2 : column.width === SIZES.LG ? 3 : 1,
          ...getAlignedContainerStyle(
            'column',
            column.horizontal_alignment || blok.default_horizontal_alignment,
            column.vertical_alignment || blok.default_vertical_alignment,
          ),
        };

        return (
          <Box
            sx={columnStyles}
            key={column._uid}
            data-test-id="row-column"
            {...storyblokEditable(column)}
          >
            {column.blocks?.map((block) => (
              <StoryblokComponent blok={block} key={block._uid} />
            ))}
          </Box>
        );
      })}
    </Flex>
  );
}
