import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  PopoverTrigger as ChakraPopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import { AUTH_MODAL_PAGES } from '../../constants/enums';
import { AUTH_MODAL_LOGIN_VIEWED, AUTH_MODAL_OPENED } from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { openAuthModal } from '../../store/modalsSlice';
import { getInitials } from '../../utils/initials';
import analyticsEvent from '../../utils/logEvent';
import SubscribeCTA from '../ctas/SubscribeCTA';
import NavLink, { NavigationItem } from './NavLink';

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> = ChakraPopoverTrigger;

const drawerButtonStyle = {
  display: { base: 'flex', md: 'none' },

  '> svg': {
    width: 8,
    height: 8,
  },
} as const;

const memberStyle = {
  alignItems: 'center',
  gap: 6,
  marginLeft: 2,
  marginBottom: 6,
} as const;

const avatarButtonStyle = {
  opacity: '0.9 !important',
  border: '0.125rem solid',
  width: { base: 10, '2xl': 12 },
  height: { base: 10, '2xl': 12 },
} as const;
interface Props {
  MainLinks: Array<NavigationItem>;
  UserLinks: Array<NavigationItem>;
}

export default function BackstageNavMobile(props: Props) {
  const { MainLinks, UserLinks } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch: any = useAppDispatch();
  const { user } = useTypedSelector((state) => state);
  const initials = getInitials(user.firstName || '', user.lastName || '');

  const isLoggedIn = !!user.firebaseUid && !!user.id;
  const isVip = !!user.isVip;
  const isLoading = user.authLoading || (user.firebaseUid && !user.id);

  const t = useTranslations('Global');

  function onClickOpenAuthModal(): void {
    analyticsEvent(AUTH_MODAL_OPENED, { origin_component: 'navigation_desktop' });
    analyticsEvent(AUTH_MODAL_LOGIN_VIEWED, { origin_component: 'navigation_desktop' });
    dispatch(openAuthModal(AUTH_MODAL_PAGES.LOGIN));
  }

  return (
    <>
      <HStack ml="auto" spacing={3}>
        {!isLoading && (
          <>
            {isLoggedIn ? (
              <Button
                sx={avatarButtonStyle}
                rounded={'full'}
                variant={'link'}
                colorScheme="black"
                disabled
                data-test-id="mobile-nav-user-button"
              >
                {initials}
              </Button>
            ) : (
              <Button
                sx={{ height: { base: 9, sm: 10 } }}
                variant="unstyled"
                data-test-id="nav-login-button"
                onClick={onClickOpenAuthModal}
              >
                {t('nav.login')}
              </Button>
            )}
          </>
        )}

        <IconButton
          sx={drawerButtonStyle}
          variant="unstyled"
          icon={<FontAwesomeIcon icon={faBars} />}
          aria-label={t('nav.openMenu')}
          onClick={onOpen}
          data-test-id="mobile-nav-drawer-button"
        />
      </HStack>

      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton size={'lg'} />
          <DrawerBody mt={14}>
            {!isLoading && isLoggedIn && (
              <Flex sx={memberStyle}>
                <Button
                  sx={avatarButtonStyle}
                  rounded={'full'}
                  variant={'link'}
                  colorScheme="black"
                  disabled
                  data-test-id="mobile-nav-user-button"
                >
                  {initials}
                </Button>
                <Text fontSize="2xl" fontWeight="800" data-test-id="mobile-nav-user-title">
                  {t(isVip ? 'nav.vipMember' : 'nav.member')}
                </Text>
              </Flex>
            )}
            <Stack as={'nav'} spacing={1}>
              {MainLinks.map((link) => (
                <NavLink key={link.title} link={link} fontSize="xl" closeNav={onClose} />
              ))}
            </Stack>
            {!isLoading && isLoggedIn && (
              <>
                <Divider />
                <Stack spacing={1} data-test-id="mobile-nav-user-menu">
                  {UserLinks.map((link) => (
                    <NavLink key={link.title} link={link} fontSize="xl" closeNav={onClose} />
                  ))}
                </Stack>
              </>
            )}
          </DrawerBody>
          <DrawerFooter>{!isVip && <SubscribeCTA />}</DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
