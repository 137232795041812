import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function useIsBackstageRoute() {
  const [isBackstageRoute, setIsBackstageRoute] = useState<boolean | null>(null);

  const router = useRouter();

  useEffect(() => {
    function checkIsBackstageRoute() {
      return (
        window.location.host.split('.')[0] === 'backstage' ||
        window.location.pathname.split('/')[1] === 'backstage' ||
        router.pathname.split('/')[1] === 'backstage'
      );
    }

    if ((window as any).Cypress && !router) {
      // return to prevent errors in cypress tests without mocked router
      return;
    }

    setIsBackstageRoute(checkIsBackstageRoute());

    router.beforePopState(({ url, as, options }) => {
      setIsBackstageRoute(checkIsBackstageRoute());
      return true;
    });
  }, [router]);

  return isBackstageRoute;
}
