import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VideoSlice {
  url: string;
}

// Routines slice stores the last retrieved routines data
// Populated as required/can be partially populated, e.g. if current set is required on first page but archive isn't
export const initialState: VideoSlice = {
  url: '',
};

const slice = createSlice({
  name: 'video',
  initialState: initialState,
  reducers: {
    clearVideoSlice(state) {
      return initialState;
    },
    setVideoUrl(state, action: PayloadAction<VideoSlice>) {
      state.url = action.payload.url;
    },
  },
});

const { actions, reducer } = slice;
export const { clearVideoSlice, setVideoUrl } = actions;
export default reducer;
