import { BossCourse, BossCourseEventData } from '../../types/bossCourses';
import { IrlRoutine, IrlRoutineEventData, Routine, RoutineEventData } from '../../types/routines';
import { Video } from '../../types/videos';

function isIrlRoutine(routine: any): routine is IrlRoutine {
  return 'startDate' in routine;
}

export function serializeRoutineEventData(routine: Routine): RoutineEventData {
  return {
    routine_artist: routine.artist,
    routine_song_name: routine.songName,
    routine_choreographer_name: routine.choreographer.name,
    routine_preview_video_url: routine.previewVideo,
    routine_queen_tutorial_video_url: routine.queenTutorialVideo,
  };
}

export function serializeIrlRoutineEventData(
  routine: IrlRoutine | Routine,
): IrlRoutineEventData | RoutineEventData {
  const irlRoutineEventData = isIrlRoutine(routine) && {
    routine_start_date: routine.startDate,
    routine_end_date: routine.endDate,
  };

  return {
    ...serializeRoutineEventData(routine),
    ...irlRoutineEventData,
  };
}

export function serializeVideoEventData(video: Video) {
  return {
    video_title: video.title,
    video_category: video.category,
    video_link: video.link,
  };
}

export function serializeBossCourseEventData(bossCourse: BossCourse): BossCourseEventData {
  return {
    title: bossCourse.title,
    image: bossCourse.image,
    dates: bossCourse.dates,
    times: bossCourse.times,
    location: bossCourse.location,
    price: bossCourse.price,
    event_id: bossCourse.eventId,
    event_href: bossCourse.eventHref,
  };
}
