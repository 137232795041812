import { Box, Container, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { COOKIE_MODAL_OPENED } from '../../constants/events';
import { BASE_URL } from '../../constants/variables';
import { useAppDispatch } from '../../hooks/store';
import { openCookieModal } from '../../store/modalsSlice';
import analyticsEvent from '../../utils/logEvent';
import NewsletterForm from '../forms/NewsletterForm';
import FooterLink from './FooterLink';
import SocialMediaLinks from './SocialMediaLinks';

const footerContainerStyle = {
  background: 'white',
  color: 'black',
} as const;

const blocksContainerStyle = {
  marginTop: 14,
  justify: 'space-between',
  flexWrap: 'wrap',
} as const;

const navigationContainerStyle = {
  flex: 1,
  flexWrap: 'wrap',
  minWidth: { base: '100%', lg: 20 },
  gap: { base: 10, sm: 24 },
  marginLeft: { base: 0, lg: '5%', xl: '10%' },
} as const;

const marketingContainerStyle = {
  maxWidth: '27.5rem',
  marginBottom: 12,
} as const;

const copyrightStyle = {
  marginTop: { base: 10, lg: 0 },
} as const;

export default function MarketingFooter() {
  const t = useTranslations('Global');
  const dispatch: any = useAppDispatch();

  function onClickOpenCookieSettings() {
    analyticsEvent(COOKIE_MODAL_OPENED, { origin_component: 'marketing_footer' });
    dispatch(openCookieModal());
  }

  return (
    <Box sx={footerContainerStyle}>
      <Container size="md">
        <Heading as="h2" size="h-3xl">
          {t('empowerTitle')}
        </Heading>
        <Flex sx={blocksContainerStyle}>
          <Box sx={marketingContainerStyle}>
            <Heading as="h3" size="h-xl">
              {t('footer.social.title')}
            </Heading>

            <NewsletterForm />

            <Text fontSize="sm" mt={3} mb={4}>
              {t('footer.unsubscribe')}
            </Text>

            <SocialMediaLinks />
          </Box>
          <Flex sx={navigationContainerStyle}>
            {/* <Box>
              <Heading as="h3" size="h-xl">
                {t('footer.hq.title')}
              </Heading>
              <FooterLink href={BASE_URL} title={t('footer.hq.herstory')} />
              <FooterLink href={BASE_URL} title={t('footer.hq.values')} />
              <FooterLink href={BASE_URL} title={t('footer.hq.team')} />
              <FooterLink
                href="https://blog.schoolofsos.com/"
                title={t('footer.hq.blog')}
                target="_blank"
              />
            </Box> */}
            <Box>
              <Heading as="h3" size="h-xl">
                {t('footer.support.title')}
              </Heading>
              <FooterLink href={`${BASE_URL}contact-us`} title={t('footer.support.contact')} />
              <FooterLink href={`${BASE_URL}privacy-policy`} title={t('footer.support.privacy')} />
              <Link display="block" fontSize="2xl" onClick={() => onClickOpenCookieSettings()}>
                {t('cookieBanner.settingsButton')}
              </Link>
            </Box>
          </Flex>
        </Flex>
        <Text sx={copyrightStyle}>{t('footer.copyright')}</Text>
      </Container>
    </Box>
  );
}
