import { Container } from '@chakra-ui/react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { IStoryblokCarousel } from '../../types/storyblokComponents';
import Carousel from '../carousels/Carousel';

interface Props {
  blok: IStoryblokCarousel;
}

export default function StoryblokCarousel(props: Props) {
  const { blok } = props;

  return (
    <Container size="lg" {...storyblokEditable(blok)}>
      <Carousel
        columns={blok.columns.map((column) => {
          return {
            content: column.blocks.map((block, index) => (
              <StoryblokComponent blok={block} key={block._uid} />
            )),
          };
        })}
        columnsPerSlide={parseInt(blok.columns_per_slide) || 1}
        autoPlay={blok.auto_play}
        hideArrows={blok.hide_buttons}
        dotsColour={blok.dots_colour}
        horizontalAlignment={blok.horizontal_alignment}
      />
    </Container>
  );
}
