import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { firebaseApp } from '../../config/firebase';
import {
  COOKIES_SETTINGS_SET,
  COOKIE_MODAL_CLOSED,
  COOKIE_STATEMENT_OPENED,
} from '../../constants/events';
import { BASE_URL } from '../../constants/variables';
import useIsBackstageRoute from '../../hooks/isBackstageRoute';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { setAuthModalViewed, updateCookies } from '../../store/cookiesSlice';
import { clearModalsSlice } from '../../store/modalsSlice';
import { addDaysToToday } from '../../utils/dateTime';
import analyticsEvent from '../../utils/logEvent';

const modalContentStyle = {
  position: 'relative',
  overflow: 'hidden',
  paddingTop: 12,
};

const accordionStyle = {
  padding: 1,
};

const titleStyle = {
  fontSize: { base: '1rem' },
  textTransform: 'uppercase',
  marginBottom: 3,
};

const textStyle = {
  fontSize: { base: '0.875rem' },
  marginBottom: 3,
};

export default function CookieModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals } = useTypedSelector((state) => state);
  const dispatch: any = useAppDispatch();
  const t = useTranslations('Global');
  const [cookies, setCookie] = useCookies(['authModalViewed', 'cookie_settings']);
  const [performanceAccepted, setperformanceAcceptedValue] = useState<boolean>(
    cookies.cookie_settings?.performance !== undefined ? cookies.cookie_settings.performance : true,
  );
  const [analyticsAccepted, setanalyticsAcceptedValue] = useState<boolean>(
    cookies.cookie_settings?.analytics !== undefined ? cookies.cookie_settings.analytics : true,
  );
  const [marketingAccepted, setmarketingAcceptedValue] = useState<boolean>(
    cookies.cookie_settings?.marketing !== undefined ? cookies.cookie_settings.marketing : true,
  );
  const isBackstageRoute = useIsBackstageRoute();

  useEffect(() => {
    if (modals.cookieModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.cookieModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    analyticsEvent(COOKIE_MODAL_CLOSED);
    dispatch(clearModalsSlice());
  }

  function onClickSaveSettings(): void {
    analyticsEvent(COOKIES_SETTINGS_SET, {
      accepted_performance: performanceAccepted,
      accepted_analytics: analyticsAccepted,
      accepted_marketing: marketingAccepted,
    });
    const settings: any = {
      path: '/',
    };
    if (window.location.host.includes('sos.dance')) {
      settings['domain'] = '.sos.dance';
    }
    setCookie(
      'cookie_settings',
      {
        necessary: true,
        performance: performanceAccepted,
        analytics: analyticsAccepted,
        marketing: marketingAccepted,
      },
      settings,
    );
    dispatch(
      updateCookies({
        performanceAccepted: performanceAccepted,
        analyticsAccepted: analyticsAccepted,
        marketingAccepted: marketingAccepted,
        settingsSet: true,
      }),
    );

    if (performanceAccepted && isBackstageRoute) {
      const expires = addDaysToToday(7);
      setCookie('authModalViewed', true, { path: '/', expires: expires });
      dispatch(setAuthModalViewed());
    }

    if (analyticsAccepted) {
      firebaseApp.automaticDataCollectionEnabled = true;
    }

    closeModal();
  }

  function onClickCookieStatementLink() {
    analyticsEvent(COOKIE_STATEMENT_OPENED);
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent data-test-id="cookie-modal-content" sx={modalContentStyle}>
        <ModalCloseButton data-test-id="cookie-modal-close-button" />
        <Container centerContent>
          <Heading size="lg">{t('cookieModal.heading')}</Heading>
        </Container>

        <Accordion allowToggle>
          <AccordionItem data-test-id="essentials-cookies-accordion">
            {({ isExpanded }) => (
              <>
                <AccordionButton data-test-id="essentials-accordion-button">
                  <HStack spacing={3}>
                    <Box sx={accordionStyle}>
                      <Text sx={titleStyle} data-test-id="essentials-heading">
                        {t('cookieModal.essentials.heading')}
                      </Text>
                    </Box>
                    <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} size="1x" />
                  </HStack>
                  <Spacer />
                  <Text>{t('cookieModal.essentials.onMessage')}</Text>
                </AccordionButton>
                <AccordionPanel>
                  <Text sx={textStyle} data-test-id="essentials-description">
                    {t('cookieModal.essentials.description')}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem data-test-id="performance-cookies-accordion">
            {({ isExpanded }) => (
              <>
                <AccordionButton data-test-id="performance-accordion-button">
                  <HStack spacing={3}>
                    <Box sx={accordionStyle}>
                      <Text sx={titleStyle} data-test-id="performance-heading">
                        {t('cookieModal.performance.heading')}
                      </Text>
                    </Box>
                    <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} size="1x" />
                  </HStack>
                  <Spacer />
                  <Switch
                    isChecked={performanceAccepted}
                    colorScheme="pink"
                    onChange={(event) => setperformanceAcceptedValue(event.target.checked)}
                    data-test-id="performance-switch"
                  />
                </AccordionButton>
                <AccordionPanel>
                  <Text sx={textStyle} data-test-id="performance-description">
                    {t('cookieModal.performance.description')}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem data-test-id="analytics-cookies-accordion">
            {({ isExpanded }) => (
              <>
                <AccordionButton data-test-id="analytics-accordion-button">
                  <HStack spacing={3}>
                    <Box sx={accordionStyle}>
                      <Text sx={titleStyle} data-test-id="analytics-heading">
                        {t('cookieModal.analytics.heading')}
                      </Text>
                    </Box>
                    <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} size="1x" />
                  </HStack>
                  <Spacer />
                  <Switch
                    isChecked={analyticsAccepted}
                    colorScheme="pink"
                    onChange={(event) => setanalyticsAcceptedValue(event.target.checked)}
                    data-test-id="analytics-switch"
                  />
                </AccordionButton>
                <AccordionPanel>
                  <Text sx={textStyle} data-test-id="analytics-description">
                    {t('cookieModal.analytics.description')}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem data-test-id="marketing-cookies-accordion">
            {({ isExpanded }) => (
              <>
                <AccordionButton data-test-id="marketing-accordion-button">
                  <HStack spacing={3}>
                    <Box sx={accordionStyle}>
                      <Text sx={titleStyle} data-test-id="marketing-heading">
                        {t('cookieModal.marketing.heading')}
                      </Text>
                    </Box>
                    <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} size="1x" />
                  </HStack>
                  <Spacer />
                  <Switch
                    isChecked={marketingAccepted}
                    colorScheme="pink"
                    onChange={(event) => setmarketingAcceptedValue(event.target.checked)}
                    data-test-id="marketing-switch"
                  />
                </AccordionButton>
                <AccordionPanel>
                  <Text sx={textStyle} data-test-id="marketing-description">
                    {t('cookieModal.marketing.description')}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        <Box padding={4}>
          <HStack>
            <NextLink href={`${BASE_URL}privacy-policy`} passHref>
              <Link onClick={() => onClickCookieStatementLink()}>
                {t('cookieModal.statementLink')}
              </Link>
            </NextLink>
            <Spacer />
            <Button
              colorScheme="black"
              onClick={() => onClickSaveSettings()}
              data-test-id="cookie-modal-save-button"
            >
              {t('cookieModal.saveSettingsButton')}
            </Button>
          </HStack>
        </Box>
      </ModalContent>
    </Modal>
  );
}
