import { Box, Button, Flex, HStack, Link } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { BACKSTAGE_URL, BASE_URL } from '../../constants/variables';
import logo from '../../public/brand/logo_white.svg';

const navBarStyle = {
  height: { base: 14, '2xl': 20 },
  alignItems: 'center',
} as const;

const logoContainerStyle = {
  position: 'relative',
  width: { base: 10, '2xl': 12 },
  height: { base: 10, '2xl': 12 },
} as const;

const buttonStyle = {
  height: { base: 8, '2xl': 10 },
  fontSize: { base: '0.875rem', '2xl': '1rem' },
} as const;

export default function MarketingNav() {
  const t = useTranslations('Global');

  const [scrollY, setScrollY] = useState(0);

  const containerStyle = {
    position: 'fixed',
    width: '100%',
    top: 0,
    paddingX: 4,
    transition: 'background-color 0.5s ease',
    background: scrollY > 0 ? 'black' : 'none',
    zIndex: 10,
  } as const;

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Box sx={containerStyle}>
        <Flex sx={navBarStyle}>
          <NextLink href={BASE_URL} passHref>
            <Link sx={logoContainerStyle} aria-label={t('home')}>
              <Image src={logo} layout="fill" objectFit="contain" alt={t('alt.logo')} />
            </Link>
          </NextLink>

          <HStack ml="auto" spacing={4}>
            <NextLink href={`${BACKSTAGE_URL}/queen-hub`} passHref>
              <Button as="a" target="_blank" sx={buttonStyle}>
                {t('nav.goBackstage')}
              </Button>
            </NextLink>
          </HStack>
        </Flex>
      </Box>
    </>
  );
}
