import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { AUTH_MODAL_PAGES, SPOTLIGHT, SUBSCRIBE_MODAL_PAGES } from '../../constants/enums';
import { SUBSCRIBE_MODAL_DETAILS_VIEWED, SUBSCRIBE_MODAL_OPENED } from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { openAuthModal, openSubscribeModal } from '../../store/modalsSlice';
import analyticsEvent from '../../utils/logEvent';
import Spotlight from '../ui/Spotlight';

const containerStyle = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  padding: 4,
  background: 'blackAlpha.50',
  textAlign: 'left',
} as const;

const backgroundStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: 'gray.25',
  zIndex: -2,
} as const;

export default function SubscribeCTA() {
  const t = useTranslations('Global.subscribeCTA');
  const dispatch: any = useAppDispatch();
  const { user } = useTypedSelector((state) => state);

  function openSubscribe() {
    const eventOrigin = 'subscribe_cta';
    analyticsEvent(SUBSCRIBE_MODAL_OPENED, { origin_component: eventOrigin });
    analyticsEvent(SUBSCRIBE_MODAL_DETAILS_VIEWED, { origin_component: eventOrigin });
    user.id
      ? dispatch(openSubscribeModal(SUBSCRIBE_MODAL_PAGES.DETAILS))
      : dispatch(openAuthModal(AUTH_MODAL_PAGES.SIGN_UP));
  }

  return (
    <Box sx={containerStyle} data-test-id="subscribe-cta">
      <Box sx={backgroundStyle} />
      <Spotlight
        option={SPOTLIGHT.LEFT_1}
        style={{
          width: '150%',
          left: '15%',
          bottom: 0,
          top: '-30%',
          opacity: 0.5,
        }}
      />
      <Box position="relative">
        <Heading as="h2" size="h-xs" mb={1} color="pink.500">
          {t('heading')}
        </Heading>
        <Text size="h-md" mb={7} maxW={500} ml={0}>
          {t('headline')}
        </Text>
        <Text size="h-sm" mb={1}>
          {t('firstMonth')}
        </Text>
        <Text fontSize="sm" textTransform="uppercase" color="pink.500">
          {t('price')}
        </Text>
        <Button size="sm" onClick={openSubscribe}>
          {t('button')}
        </Button>
      </Box>
    </Box>
  );
}
