import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Video } from '../types/videos';
import { api } from './api';

export interface VideosSlice {
  yoga: Video[] | null;
  stories: Video[] | null;
  lifeSkills: Video[] | null;
  qa: Video[] | null;
  mindset: Video[] | null;
}

// Routines slice stores the last retrieved routines data
// Populated as required/can be partially populated, e.g. if current set is required on first page but archive isn't
export const initialState: VideosSlice = {
  yoga: null,
  stories: null,
  qa: null,
  lifeSkills: null,
  mindset: null,
};

const slice = createSlice({
  name: 'videos',
  initialState: initialState,
  reducers: {
    clearClassesSlice(state) {
      return initialState;
    },
    updateVideos(state, action: PayloadAction<Partial<VideosSlice>>) {
      return Object.assign({}, state, action.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getYogaVideos.matchFulfilled, (state, { payload }) => {
      const videos = payload as Video[];
      state.yoga = videos;
      return state;
    });
    builder.addMatcher(api.endpoints.getLifeSkillsVideos.matchFulfilled, (state, { payload }) => {
      const videos = payload as Video[];
      state.lifeSkills = videos;
      return state;
    });
    builder.addMatcher(api.endpoints.getQAVideos.matchFulfilled, (state, { payload }) => {
      const videos = payload as Video[];
      state.qa = videos;
      return state;
    });
    builder.addMatcher(api.endpoints.getStoriesVideos.matchFulfilled, (state, { payload }) => {
      const videos = payload as Video[];
      state.stories = videos;
      return state;
    });
    builder.addMatcher(api.endpoints.getMindsetVideos.matchFulfilled, (state, { payload }) => {
      const videos = payload as Video[];
      state.mindset = videos;
      return state;
    });
  },
});

const { actions, reducer } = slice;
export const { clearClassesSlice, updateVideos } = actions;
export default reducer;
