export const variantSolid = (props: { colorScheme: string }) => {
  const { colorScheme } = props;

  if (colorScheme === 'black') {
    return {
      color: 'white',
      background: 'black',
      _hover: {
        background: 'gray.800',
        _disabled: {
          background: 'black',
        },
      },
      _active: {
        background: 'gray.800',
      },
    };
  }

  if (colorScheme === 'white') {
    return {
      color: 'black',
      background: 'white',
      _hover: {
        background: 'gray.200',
        _disabled: {
          background: 'gray.200',
        },
      },
      _active: {
        background: 'gray.300',
      },
    };
  }

  if (colorScheme === 'gray') {
    return {
      color: 'black',
      background: 'gray.100',
      _hover: {
        background: 'gray.300',
        _disabled: {
          background: 'gray.100',
        },
      },
      _active: {
        background: 'gray.400',
      },
    };
  }
};

export const variantGhost = (props: { colorScheme: string }) => {
  const { colorScheme } = props;

  if (colorScheme === 'black') {
    return {
      color: 'black',
      _hover: {
        background: 'blackAlpha.200',
      },
      _active: {
        background: 'blackAlpha.300',
      },
    };
  }

  if (colorScheme === 'white') {
    return {
      color: 'white',
      _hover: {
        background: 'whiteAlpha.300',
      },
      _active: {
        background: 'whiteAlpha.400',
      },
    };
  }

  if (colorScheme === 'gray') {
    return {
      color: 'gray.100',
      _hover: {
        background: 'gray.500',
      },
      _active: {
        background: 'gray.600',
      },
    };
  }

  if (colorScheme === 'pink') {
    return {
      color: 'pink.500',
      _hover: {
        background: 'pink.100',
      },
      _active: {
        background: 'pink.200',
      },
    };
  }
};

export const variantOutline = (props: { colorScheme: string }) => {
  const { colorScheme } = props;
  return {
    border: '0.0625rem solid',
    borderColor: colorScheme === 'pink' ? 'pink.500' : 'currentColor',
    ...variantGhost(props),
  };
};

export const variantLink = (props: { colorScheme: string }) => {
  const { colorScheme } = props;
  if (colorScheme === 'black') {
    return {
      color: 'black',
    };
  }
  if (colorScheme === 'gray') {
    return {
      color: 'gray.100',
    };
  }
};
