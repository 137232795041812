import { Box, Container } from '@chakra-ui/react';
import Image from 'next/image';
import { COLOURS, OVERLAYS, SPOTLIGHT } from '../../constants/enums';
import { backgroundVideoEvents } from '../../constants/events';
import { backgroundImageContainerStyle } from '../../styles/global';
import Spotlight from './Spotlight';
import VideoPlayer from './VideoPlayer';

interface Props {
  backgroundImage?: string;
  backgroundImageAlt?: string;
  backgroundVideo?: string;
  backgroundColour?: COLOURS;
  backgroundOverlay?: OVERLAYS;
  backgroundSpotlight?: SPOTLIGHT;
  children: JSX.Element;
  videoAutoplay?: boolean;
  styles?: any;
}

export default function PageSectionBackground(props: Props) {
  const {
    backgroundImage,
    backgroundImageAlt,
    backgroundVideo,
    backgroundColour,
    backgroundOverlay,
    backgroundSpotlight,
    videoAutoplay = false,
    children,
    styles,
  } = props;

  const containerStyle = {
    ...styles,
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: (!backgroundVideo && !backgroundImage && backgroundColour) || 'initial',
    color:
      !backgroundVideo && !backgroundImage && backgroundColour === COLOURS.WHITE
        ? 'black'
        : 'inherit',
  } as const;

  const overlayStyle = {
    backgroundImage: backgroundOverlay,
    width: '100%',
    height: '100%',
    position: 'absolute',
  };

  return (
    <Container sx={containerStyle} size="lg" centerContent data-test-id="background-container">
      {backgroundVideo ? (
        <VideoPlayer
          autoplay={videoAutoplay}
          url={backgroundVideo}
          isBackground={true}
          loop={videoAutoplay ? true : false}
          muted={true}
          useCustomFullscreenControl={false}
          analyticsEvents={backgroundVideoEvents}
          analyticsEventsData={{ origin_component: 'page_section_background' }}
        />
      ) : (
        backgroundImage && (
          <Box sx={backgroundImageContainerStyle}>
            <Image
              src={backgroundImage}
              layout="fill"
              objectFit="cover"
              objectPosition="top"
              quality={100}
              alt={backgroundImageAlt}
              data-test-id="image"
            />
          </Box>
        )
      )}

      {backgroundOverlay && <Box sx={overlayStyle} data-test-id="overlay" />}
      {backgroundSpotlight && <Spotlight option={backgroundSpotlight} />}

      <Box zIndex={1} w={'100%'}>
        {children}
      </Box>
    </Container>
  );
}
