import { Box, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { COLOURS, EXTENDED_SIZES, HORIZONTAL_ALIGNMENTS, SPOTLIGHT } from '../../constants/enums';
import { useGetChoreographersQuery } from '../../store/api';
import { Choreographer } from '../../types/choreographers';
import Carousel from '../carousels/Carousel';
import ChoreographerContent from '../ui/ChoreographerContent';
import Spotlight from '../ui/Spotlight';

interface Props {
  backgroundColor?: COLOURS;
}

export default function ChoreographerCarousel(props: Props) {
  const { backgroundColor = COLOURS.WHITE } = props;

  const t = useTranslations('Global');

  const { data, error, isLoading } = useGetChoreographersQuery();

  if (!data || error || isLoading) {
    return <></>;
  }

  const choreographers = data as Choreographer[];

  const containerStyle = {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: backgroundColor,
    color: backgroundColor === COLOURS.WHITE ? COLOURS.BLACK : 'inherit',
  } as const;

  const dotsColor = backgroundColor === COLOURS.WHITE ? COLOURS.BLACK : COLOURS.WHITE;

  return (
    <Box sx={containerStyle} width="full" data-test-id="choreographer-carousel">
      <Spotlight
        option={SPOTLIGHT.RIGHT_1}
        style={{
          width: '150%',
          left: { base: '-10%', md: '40%', lg: '50%' },
          bottom: 0,
          top: { base: '-40%', md: '-30%' },
        }}
      />

      <Carousel
        columns={choreographers.map((choreographer, index) => {
          return {
            content: [
              <Box key={`choreographer-content-${index}`}>
                <Text size="h-md" mb="1rem !important">
                  {t('meetChoreographers')}
                </Text>
                <ChoreographerContent choreographer={choreographer} />
              </Box>,
            ],
            imageUrl: choreographer.image,
            imageAlt: t('alt.photoOf', { name: choreographer.name }),
          };
        })}
        hasAlignedImage={true}
        autoPlay={false}
        dotsColour={dotsColor}
        horizontalAlignment={HORIZONTAL_ALIGNMENTS.LEFT}
        height={EXTENDED_SIZES.MD}
      />
    </Box>
  );
}
