import {
  IStoryblokHero,
  IStoryblokPageSection,
  IStoryblokTwoColumnPageSection,
} from '../../types/storyblokComponents';
import PageSectionBackground from '../ui/PageSectionBackground';

interface Props {
  blok: IStoryblokHero | IStoryblokPageSection | IStoryblokTwoColumnPageSection;
  children: JSX.Element;
  styles?: any;
  videoAutoplay?: boolean;
}

export default function StoryblokSectionBackground(props: Props) {
  const { blok, children, styles, videoAutoplay = true } = props;

  return (
    <PageSectionBackground
      backgroundImage={blok.background_image?.filename}
      backgroundImageAlt={blok.background_image?.alt}
      backgroundVideo={blok.background_video?.url}
      backgroundColour={blok.background_colour}
      backgroundOverlay={blok.background_overlay}
      backgroundSpotlight={blok.background_spotlight}
      videoAutoplay={videoAutoplay}
      styles={styles}
    >
      {children}
    </PageSectionBackground>
  );
}
