export const APP_LOADED = 'app_loaded';

export const SUBSCRIBED_TO_NEWSLETTER = 'subscribed_to_newsletter';
export const SUBSCRIBED_TO_NEWSLETTER_ERROR = 'subscribed_to_newsletter_error';

export const USER_SIGNED_UP = 'user_signed_up';
export const USER_SIGNED_UP_ERROR = 'user_signed_up_error';
export const USER_UPDATED_PROFILE = 'user_updated_profile';
export const USER_UPDATED_PROFILE_ERROR = 'user_updated_profile_error';
export const USER_LOGGED_IN = 'user_logged_in';
export const GET_USER_ERROR = 'get_user_error';

export const FIND_CLASS_SEARCH_STARTED = 'find_class_search_started';
export const FIND_CLASS_PLACE_SELECTED = 'find_class_place_selected';
export const FIND_CLASS_CLASSES_FOUND = 'find_class_classes_found';
export const FIND_CLASS_NO_CLASSES_FOUND = 'find_class_no_classes_found';
export const BOOK_CLASS_OPEN_MODAL = 'book_class_open_modal';
export const BOOK_CLASS_BOOKED = 'book_class_booked';

export const BOOK_BOSS_COURSE_OPEN_MODAL = 'book_boss_course_open_modal';
export const BOOK_BOSS_COURSE_BOOKED = 'book_boss_course_booked';
export const BOOK_BOSS_COURSE_MORE_INFO_CLICKED = 'book_boss_more_info_clicked';

export const AUTH_MODAL_OPENED = 'auth_modal_opened';
export const AUTH_MODAL_OPENED_ORIGIN_CLASS = 'auth_modal_opened_origin_class';
export const AUTH_MODAL_CLOSED = 'auth_modal_closed';
export const AUTH_MODAL_LOGIN_VIEWED = 'auth_modal_login_viewed';
export const AUTH_MODAL_SIGN_UP_VIEWED = 'auth_modal_sign_up_viewed';
export const AUTH_MODAL_SUCCESS_VIEWED = 'auth_modal_success_viewed';

export const SUBSCRIBE_MODAL_OPENED = 'subscribe_modal_opened';
export const SUBSCRIBE_MODAL_CLOSED = 'subscribe_modal_closed';
export const SUBSCRIBE_MODAL_DETAILS_VIEWED = 'subscribe_modal_details_viewed';
export const SUBSCRIBE_MODAL_SUCCESS_VIEWED = 'subscribe_modal_success_viewed';

export const COOKIE_MODAL_OPENED = 'cookie_modal_opened';
export const COOKIE_MODAL_CLOSED = 'cookie_modal_closed';
export const COOKIE_STATEMENT_OPENED = 'cookie_statement_opened';

export const COOKIES_ACCEPTED = 'cookies_accepted';
export const COOKIES_SETTINGS_SET = 'cookie_settings_set';

export const PROFILE_MODAL_OPENED = 'profile_modal_opened';
export const PROFILE_MODAL_CLOSED = 'profile_modal_closed';

export const VIDEO_MODAL_OPENED = 'video_modal_opened';
export const VIDEO_MODAL_CLOSED = 'video_modal_closed';

export const SUBSCRIBE_CHECKOUT_LINK_OPENED = 'subscribe_checkout_link_opened';
export const SUBSCRIBE_CHECKOUT_LINK_ERROR = 'subscribe_checkout_link_error';
export const SUBSCRIBE_CHECKOUT_REDIRECT_COMPLETE = 'subscribe_checkout_redirect_complete';

export const ROUTINE_TUTORIAL_SECTION_PREVIOUS_CLICKED =
  'routine_tutorial_section_previous_clicked';
export const ROUTINE_TUTORIAL_SECTION_CURRENT_CLICKED = 'routine_tutorial_section_current_clicked';
export const ROUTINE_TUTORIAL_SECTION_NEXT_CLICKED = 'routine_tutorial_section_next_clicked';

export const ROUTINE_PREVIEW_SECTION_PREVIOUS_CLICKED = 'routine_preview_section_previous_clicked';
export const ROUTINE_PREVIEW_SECTION_CURRENT_CLICKED = 'routine_preview_section_current_clicked';

export const CURRENT_ROUTINE_PREVIEW_VIDEO_PLAYED = 'current_routine_preview_video_played';
export const CURRENT_ROUTINE_PREVIEW_VIDEO_PAUSED = 'current_routine_preview_video_paused';
export const CURRENT_ROUTINE_PREVIEW_VIDEO_ENDED = 'current_routine_preview_video_ended';

export const CURRENT_ROUTINE_TUTORIAL_VIDEO_PLAYED = 'current_routine_tutorial_video_played';
export const CURRENT_ROUTINE_TUTORIAL_VIDEO_PAUSED = 'current_routine_tutorial_video_paused';
export const CURRENT_ROUTINE_TUTORIAL_VIDEO_ENDED = 'current_routine_tutorial_video_ended';

export const NEXT_ROUTINE_PREVIEW_VIDEO_PLAYED = 'next_routine_preview_video_played';
export const NEXT_ROUTINE_PREVIEW_VIDEO_PAUSED = 'next_routine_preview_video_paused';
export const NEXT_ROUTINE_PREVIEW_VIDEO_ENDED = 'next_routine_preview_video_ended';

export const NEXT_ROUTINE_TUTORIAL_VIDEO_PLAYED = 'next_routine_tutorial_video_played';
export const NEXT_ROUTINE_TUTORIAL_VIDEO_PAUSED = 'next_routine_tutorial_video_paused';
export const NEXT_ROUTINE_TUTORIAL_VIDEO_ENDED = 'next_routine_tutorial_video_ended';

export const PREVIOUS_ROUTINE_PREVIEW_VIDEO_PLAYED = 'previous_routine_preview_video_played';
export const PREVIOUS_ROUTINE_PREVIEW_VIDEO_PAUSED = 'previous_routine_preview_video_paused';
export const PREVIOUS_ROUTINE_PREVIEW_VIDEO_ENDED = 'previous_routine_preview_video_ended';

export const PREVIOUS_ROUTINE_TUTORIAL_VIDEO_PLAYED = 'previous_routine_tutorial_video_played';
export const PREVIOUS_ROUTINE_TUTORIAL_VIDEO_PAUSED = 'previous_routine_tutorial_video_paused';
export const PREVIOUS_ROUTINE_TUTORIAL_VIDEO_ENDED = 'previous_routine_tutorial_video_ended';

export const EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_PLAYED = 'exclusive_routine_preview_video_played';
export const EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_PAUSED = 'exclusive_routine_preview_video_paused';
export const EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_ENDED = 'exclusive_routine_preview_video_ended';

export const EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_PLAYED = 'exclusive_routine_tutorial_video_played';
export const EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_PAUSED = 'exclusive_routine_tutorial_video_paused';
export const EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_ENDED = 'exclusive_routine_tutorial_video_ended';

export const ARCHIVE_ROUTINE_PREVIEW_VIDEO_PLAYED = 'archive_routine_preview_video_played';
export const ARCHIVE_ROUTINE_PREVIEW_VIDEO_PAUSED = 'archive_routine_preview_video_paused';
export const ARCHIVE_ROUTINE_PREVIEW_VIDEO_ENDED = 'archive_routine_preview_video_ended';

export const ARCHIVE_ROUTINE_TUTORIAL_VIDEO_PLAYED = 'archive_routine_tutorial_video_played';
export const ARCHIVE_ROUTINE_TUTORIAL_VIDEO_PAUSED = 'archive_routine_tutorial_video_paused';
export const ARCHIVE_ROUTINE_TUTORIAL_VIDEO_ENDED = 'archive_routine_tutorial_video_ended';

export const YOGA_VIDEO_PLAYED = 'yoga_video_played';
export const YOGA_VIDEO_PAUSED = 'yoga_video_paused';
export const YOGA_VIDEO_ENDED = 'yoga_video_ended';

export const QA_VIDEO_PLAYED = 'qa_video_played';
export const QA_VIDEO_PAUSED = 'qa_video_paused';
export const QA_VIDEO_ENDED = 'qa_video_ended';

export const LIFE_SKILL_VIDEO_PLAYED = 'life_skill_video_played';
export const LIFE_SKILL_VIDEO_PAUSED = 'life_skill_video_paused';
export const LIFE_SKILL_VIDEO_ENDED = 'life_skill_video_ended';

export const MINDSET_VIDEO_PLAYED = 'mindset_video_played';
export const MINDSET_VIDEO_PAUSED = 'mindset_video_paused';
export const MINDSET_VIDEO_ENDED = 'mindset_video_ended';

export const STORY_VIDEO_PLAYED = 'story_video_played';
export const STORY_VIDEO_PAUSED = 'story_video_paused';
export const STORY_VIDEO_ENDED = 'story_video_ended';

export const BACKGROUND_VIDEO_PLAYED = 'background_video_played';
export const BACKGROUND_VIDEO_PAUSED = 'background_video_paused';
export const BACKGROUND_VIDEO_ENDED = 'background_video_ended';

export const CONTENT_VIDEO_PLAYED = 'content_video_played';
export const CONTENT_VIDEO_PAUSED = 'content_video_paused';
export const CONTENT_VIDEO_ENDED = 'content_video_ended';

export interface VideoEvents {
  played:
    | typeof CURRENT_ROUTINE_PREVIEW_VIDEO_PLAYED
    | typeof CURRENT_ROUTINE_TUTORIAL_VIDEO_PLAYED
    | typeof NEXT_ROUTINE_PREVIEW_VIDEO_PLAYED
    | typeof NEXT_ROUTINE_TUTORIAL_VIDEO_PLAYED
    | typeof PREVIOUS_ROUTINE_PREVIEW_VIDEO_PLAYED
    | typeof PREVIOUS_ROUTINE_TUTORIAL_VIDEO_PLAYED
    | typeof EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_PLAYED
    | typeof EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_PLAYED
    | typeof ARCHIVE_ROUTINE_PREVIEW_VIDEO_PLAYED
    | typeof ARCHIVE_ROUTINE_TUTORIAL_VIDEO_PLAYED
    | typeof YOGA_VIDEO_PLAYED
    | typeof QA_VIDEO_PLAYED
    | typeof LIFE_SKILL_VIDEO_PLAYED
    | typeof STORY_VIDEO_PLAYED
    | typeof MINDSET_VIDEO_PLAYED
    | typeof BACKGROUND_VIDEO_PLAYED
    | typeof CONTENT_VIDEO_PLAYED;
  paused:
    | typeof CURRENT_ROUTINE_PREVIEW_VIDEO_PAUSED
    | typeof CURRENT_ROUTINE_TUTORIAL_VIDEO_PAUSED
    | typeof NEXT_ROUTINE_PREVIEW_VIDEO_PAUSED
    | typeof NEXT_ROUTINE_TUTORIAL_VIDEO_PAUSED
    | typeof PREVIOUS_ROUTINE_PREVIEW_VIDEO_PAUSED
    | typeof PREVIOUS_ROUTINE_TUTORIAL_VIDEO_PAUSED
    | typeof EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_PAUSED
    | typeof EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_PAUSED
    | typeof ARCHIVE_ROUTINE_PREVIEW_VIDEO_PAUSED
    | typeof ARCHIVE_ROUTINE_TUTORIAL_VIDEO_PAUSED
    | typeof YOGA_VIDEO_PAUSED
    | typeof QA_VIDEO_PAUSED
    | typeof LIFE_SKILL_VIDEO_PAUSED
    | typeof STORY_VIDEO_PAUSED
    | typeof MINDSET_VIDEO_PAUSED
    | typeof BACKGROUND_VIDEO_PAUSED
    | typeof CONTENT_VIDEO_PAUSED;
  ended:
    | typeof CURRENT_ROUTINE_PREVIEW_VIDEO_ENDED
    | typeof CURRENT_ROUTINE_TUTORIAL_VIDEO_ENDED
    | typeof NEXT_ROUTINE_PREVIEW_VIDEO_ENDED
    | typeof NEXT_ROUTINE_TUTORIAL_VIDEO_ENDED
    | typeof PREVIOUS_ROUTINE_PREVIEW_VIDEO_ENDED
    | typeof PREVIOUS_ROUTINE_TUTORIAL_VIDEO_ENDED
    | typeof EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_ENDED
    | typeof EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_ENDED
    | typeof ARCHIVE_ROUTINE_PREVIEW_VIDEO_ENDED
    | typeof ARCHIVE_ROUTINE_TUTORIAL_VIDEO_ENDED
    | typeof YOGA_VIDEO_ENDED
    | typeof QA_VIDEO_ENDED
    | typeof LIFE_SKILL_VIDEO_ENDED
    | typeof STORY_VIDEO_ENDED
    | typeof MINDSET_VIDEO_ENDED
    | typeof BACKGROUND_VIDEO_ENDED
    | typeof CONTENT_VIDEO_ENDED;
}

export const currentRoutinePreviewEvents: VideoEvents = {
  played: CURRENT_ROUTINE_PREVIEW_VIDEO_PLAYED,
  paused: CURRENT_ROUTINE_PREVIEW_VIDEO_PAUSED,
  ended: CURRENT_ROUTINE_PREVIEW_VIDEO_ENDED,
};

export const currentRoutineTutorialEvents: VideoEvents = {
  played: CURRENT_ROUTINE_TUTORIAL_VIDEO_PLAYED,
  paused: CURRENT_ROUTINE_TUTORIAL_VIDEO_PAUSED,
  ended: CURRENT_ROUTINE_TUTORIAL_VIDEO_ENDED,
};

export const nextRoutinePreviewEvents: VideoEvents = {
  played: NEXT_ROUTINE_PREVIEW_VIDEO_PLAYED,
  paused: NEXT_ROUTINE_PREVIEW_VIDEO_PAUSED,
  ended: NEXT_ROUTINE_PREVIEW_VIDEO_ENDED,
};

export const nextRoutineTutorialEvents: VideoEvents = {
  played: NEXT_ROUTINE_TUTORIAL_VIDEO_PLAYED,
  paused: NEXT_ROUTINE_TUTORIAL_VIDEO_PAUSED,
  ended: NEXT_ROUTINE_TUTORIAL_VIDEO_ENDED,
};

export const previousRoutinePreviewEvents: VideoEvents = {
  played: PREVIOUS_ROUTINE_PREVIEW_VIDEO_PLAYED,
  paused: PREVIOUS_ROUTINE_PREVIEW_VIDEO_PAUSED,
  ended: PREVIOUS_ROUTINE_PREVIEW_VIDEO_ENDED,
};

export const previousRoutineTutorialEvents: VideoEvents = {
  played: PREVIOUS_ROUTINE_TUTORIAL_VIDEO_PLAYED,
  paused: PREVIOUS_ROUTINE_TUTORIAL_VIDEO_PAUSED,
  ended: PREVIOUS_ROUTINE_TUTORIAL_VIDEO_ENDED,
};

export const archiveRoutinePreviewEvents: VideoEvents = {
  played: ARCHIVE_ROUTINE_PREVIEW_VIDEO_PLAYED,
  paused: ARCHIVE_ROUTINE_PREVIEW_VIDEO_PAUSED,
  ended: ARCHIVE_ROUTINE_PREVIEW_VIDEO_ENDED,
};

export const archiveRoutineTutorialEvents: VideoEvents = {
  played: ARCHIVE_ROUTINE_TUTORIAL_VIDEO_PLAYED,
  paused: ARCHIVE_ROUTINE_TUTORIAL_VIDEO_PAUSED,
  ended: ARCHIVE_ROUTINE_TUTORIAL_VIDEO_ENDED,
};

export const exclusiveRoutinePreviewEvents: VideoEvents = {
  played: EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_PLAYED,
  paused: EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_PAUSED,
  ended: EXCLUSIVE_ROUTINE_PREVIEW_VIDEO_ENDED,
};

export const exclusiveRoutineTutorialEvents: VideoEvents = {
  played: EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_PLAYED,
  paused: EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_PAUSED,
  ended: EXCLUSIVE_ROUTINE_TUTORIAL_VIDEO_ENDED,
};

export const yogaVideoEvents: VideoEvents = {
  played: YOGA_VIDEO_PLAYED,
  paused: YOGA_VIDEO_PAUSED,
  ended: YOGA_VIDEO_ENDED,
};
export const qaVideoEvents: VideoEvents = {
  played: QA_VIDEO_PLAYED,
  paused: QA_VIDEO_PAUSED,
  ended: QA_VIDEO_ENDED,
};
export const lifeSkillVideoEvents: VideoEvents = {
  played: LIFE_SKILL_VIDEO_PLAYED,
  paused: LIFE_SKILL_VIDEO_PAUSED,
  ended: LIFE_SKILL_VIDEO_ENDED,
};
export const mindsetVideoEvents: VideoEvents = {
  played: MINDSET_VIDEO_PLAYED,
  paused: MINDSET_VIDEO_PAUSED,
  ended: MINDSET_VIDEO_ENDED,
};
export const storyVideoEvents: VideoEvents = {
  played: STORY_VIDEO_PLAYED,
  paused: STORY_VIDEO_PAUSED,
  ended: STORY_VIDEO_ENDED,
};
export const backgroundVideoEvents: VideoEvents = {
  played: BACKGROUND_VIDEO_PLAYED,
  paused: BACKGROUND_VIDEO_PAUSED,
  ended: BACKGROUND_VIDEO_ENDED,
};
export const contentVideoEvents: VideoEvents = {
  played: BACKGROUND_VIDEO_PLAYED,
  paused: BACKGROUND_VIDEO_PAUSED,
  ended: BACKGROUND_VIDEO_ENDED,
};
