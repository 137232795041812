import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer';
import { RichTextConfig } from '../../config/storyblokRichtext';
import { IStoryblokRichtext } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokRichtext;
}

export default function StoryblokRichtext(props: Props) {
  const { blok } = props;
  return (
    <Box color={blok.colour} {...storyblokEditable(blok)}>
      {render(blok.content, RichTextConfig)}
    </Box>
  );
}
