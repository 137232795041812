import InstagramEmbed from 'react-instagram-embed';
import { IStoryblokInstagram } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokInstagram;
}

const StoryblokInstagramEmbed = (props: Props) => {
  const { blok } = props;

  const clientAccessToken = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ACCESS_TOKEN;

  if (!clientAccessToken) return <></>;

  // NOTE: Instagram embeds rely on setup between facebook/instagram and our app
  // See https://developers.facebook.com/docs/instagram/oembed/#requirements
  return (
    <InstagramEmbed
      url="https://www.instagram.com/schoolofsos"
      clientAccessToken={clientAccessToken}
      maxWidth={1200}
      hideCaption={false}
      containerTagName="div"
      protocol=""
      injectScript
      onLoading={() => {}}
      onSuccess={() => {}}
      onAfterRender={() => {}}
      onFailure={() => {}}
    />
  );
};
export default StoryblokInstagramEmbed;
