import { createSlice } from '@reduxjs/toolkit';
import { BossClass } from '../types/classes';
import { api } from './api';

// Classes slice stores the last retrieved list of classes, e.g. after calling getClasses
export const initialState: BossClass[] = [];

const slice = createSlice({
  name: 'classes',
  initialState: initialState,
  reducers: {
    clearClassesSlice(state) {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getClasses.matchFulfilled, (state, { payload }) => {
      if (payload instanceof Array) {
        state = payload;
      }
      return state;
    });
  },
});

const { actions, reducer } = slice;
export const { clearClassesSlice } = actions;
export default reducer;
