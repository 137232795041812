export const colours = {
  black: '#000000',
  white: '#ffffff',
  pink: {
    50: '#FFE5EA',
    100: '#FFB8C4',
    200: '#FF8A9F',
    300: '#FF5C79',
    400: '#FF2E54',
    500: '#FF002E',
    600: '#CC0025',
    700: '#99001C',
    800: '#660012',
    900: '#330009',
  },
  gray: {
    50: '#F5F5F5',
    100: '#F0F0F0',
    200: '#EBEBEB',
    300: '#D6D6D6',
    400: '#C2C2C2',
    500: '#A3A3A3',
    600: '#858585',
    700: '#5C5C5C',
    800: '#333333',
    900: '#141414',
  },
};
