import {
  Box,
  Flex,
  Link,
  PopoverTrigger as ChakraPopoverTrigger,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { firebaseAuth } from '../../config/firebase';
import { PROFILE_MODAL_OPENED } from '../../constants/events';
import { BACKSTAGE_URL } from '../../constants/variables';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import logo from '../../public/brand/logo.svg';
import { api } from '../../store/api';
import { setAuthModalViewed } from '../../store/cookiesSlice';
import { clearModalsSlice, openProfileModal } from '../../store/modalsSlice';
import { clearUserSlice } from '../../store/userSlice';
import { addDaysToToday } from '../../utils/dateTime';
import analyticsEvent from '../../utils/logEvent';
import BackstageNavDesktop from './BackstageNavDesktop';
import BackstageNavMobile from './BackstageNavMobile';
import { NavigationItem } from './NavLink';

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> = ChakraPopoverTrigger;

const containerStyle = {
  position: 'sticky',
  width: '100%',
  top: 0,
  background: 'white',
  color: 'black',
  paddingX: 4,
  borderBottom: '0.0625rem solid',
  borderColor: 'gray.400',
  zIndex: 10,
} as const;

const navBarStyle = {
  height: { base: 14, '2xl': 20 },
  alignItems: 'center',
} as const;

const logoContainerStyle = {
  position: 'relative',
  width: { base: 10, '2xl': 12 },
  height: { base: 10, '2xl': 12 },
} as const;

export default function BackstageNav() {
  const [cookies, setCookie] = useCookies(['authModalViewed']);
  const { cookiesState } = useTypedSelector((state) => state);

  const t = useTranslations('Global');
  const router = useRouter();
  const pathname = router.pathname;
  const dispatch: any = useAppDispatch();

  async function logout() {
    await dispatch(clearModalsSlice());
    await dispatch(clearUserSlice());
    await dispatch(api.util.resetApiState());

    if (cookiesState.performanceAccepted) {
      // Set authModalViewed cookie with expiry of 1 day from now
      const expires = addDaysToToday(1);
      setCookie('authModalViewed', { path: '/', expires: expires });
    }
    dispatch(setAuthModalViewed());

    // sign out of firebase
    await firebaseAuth.signOut();
  }

  async function onClickEditProfile() {
    analyticsEvent(PROFILE_MODAL_OPENED);
    dispatch(openProfileModal());
  }

  const MainLinks: Array<NavigationItem> = [
    {
      title: t('nav.queenHub'),
      href: `${BACKSTAGE_URL}/queen-hub`,
      isActive: pathname === '/backstage/queen-hub',
    },
    {
      title: t('nav.vipVideos'),
      href: `${BACKSTAGE_URL}/vip-videos`,
      isActive: pathname === '/backstage/vip-videos',
    },
    {
      title: t('nav.becomeBoss'),
      href: `${BACKSTAGE_URL}/boss-hub`,
      isActive: pathname === '/backstage/boss-hub',
    },
  ];

  const UserLinks: Array<NavigationItem> = [
    {
      title: t('nav.editProfile'),
      isActive: false,
      onClick: onClickEditProfile,
    },
    {
      title: t('nav.logout'),
      isActive: false,
      onClick: logout,
    },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Box sx={containerStyle}>
        <Flex sx={navBarStyle}>
          <NextLink href={`${BACKSTAGE_URL}/queen-hub`} passHref>
            <Link sx={logoContainerStyle} aria-label={t('home')}>
              <Image src={logo} layout="fill" objectFit="contain" alt={t('alt.logo')} />
            </Link>
          </NextLink>
          {isMobile ? (
            <BackstageNavMobile MainLinks={MainLinks} UserLinks={UserLinks} />
          ) : (
            <BackstageNavDesktop MainLinks={MainLinks} UserLinks={UserLinks} />
          )}
        </Flex>
      </Box>
    </>
  );
}
