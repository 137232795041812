import { Box, Button, Input, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { SUBSCRIBED_TO_NEWSLETTER, SUBSCRIBED_TO_NEWSLETTER_ERROR } from '../../constants/events';
import { useSubscribeToNewsletterMutation } from '../../store/api';
import analyticsEvent from '../../utils/logEvent';

const fieldContainerStyle = {
  width: '100%',
  display: 'flex',
} as const;

const inputStyle = {
  flex: 1,
  marginRight: -2,
  height: { base: '2.75rem', '2xl': '3rem' },
} as const;

const buttonStyle = {
  paddingX: 3,
  fontSize: '1rem',
} as const;

const feedbackStyle = {
  marginTop: 2,
  fontWeight: 'bold',
} as const;

export default function NewsletterForm() {
  const [emailValue, setEmailValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [subscribeToNewsletter] = useSubscribeToNewsletterMutation();

  const t = useTranslations('Global.footer.form');

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const response = await subscribeToNewsletter({
      email: emailValue,
    });

    if ('data' in response) {
      analyticsEvent(SUBSCRIBED_TO_NEWSLETTER);
      setFormSuccess(true);
      setFormError('');
    }

    if ('error' in response) {
      analyticsEvent(SUBSCRIBED_TO_NEWSLETTER_ERROR, { error: response.error });
      setFormError(t('error'));
      setFormSuccess(false);
      throw response.error;
    }

    setEmailValue('');
    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={fieldContainerStyle}>
        <Input
          sx={inputStyle}
          placeholder={t('emailPlaceholder')}
          value={emailValue}
          type="email"
          size="lg"
          variant="filled"
          required
          onChange={(e) => setEmailValue(e.target.value)}
        />
        <Button sx={buttonStyle} type="submit" isLoading={loading} disabled={loading} size="lg">
          {t('submit')}
        </Button>
      </Box>

      {formSuccess && (
        <Text id="success" sx={feedbackStyle}>
          {t('success')}
        </Text>
      )}
      {formError && (
        <Text id="error" sx={feedbackStyle}>
          {formError}
        </Text>
      )}
    </form>
  );
}
