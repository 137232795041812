import { Place, PlaceEventData } from '../../types/places';

export function serializePlace(place: google.maps.places.PlaceResult): Place {
  const geometry = {
    geometry: {
      location: {
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      },
    },
  };

  const serializedPlace: Place = Object.assign({}, place, geometry);
  return serializedPlace;
}

// Serializes a google place result to pull out the values onto one level
// Firebase analytics event params don't work well with nested values
// Maintains snake case for firebase events
export function serializePlaceForEvents(
  place: google.maps.places.AutocompletePrediction,
  details: google.maps.places.PlaceResult,
): PlaceEventData {
  let placeValues: any = {
    place_id: details.place_id || '',
    place_name: details.name || '',
    place_description: place.description,
  };

  if (typeof details?.address_components === 'object') {
    details?.address_components?.forEach((addressComponent) => {
      placeValues[`place_${addressComponent.types[0]}`] = addressComponent.long_name;
    });
  }
  return placeValues;
}
