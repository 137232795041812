import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import { getAlignedMarginStyle, getImageSizeStyle } from '../../styles/global';
import { IStoryblokVideo } from '../../types/storyblokComponents';
import VideoPlayer from '../ui/VideoPlayer';

interface Props {
  blok: IStoryblokVideo;
}
export default function StoryblokVideo(props: Props) {
  const { blok } = props;

  if (!blok.video || !blok.video.url) return <></>;

  const containerStyle = {
    position: 'relative',
    minWidth: 200,
    ...getImageSizeStyle(blok.size),
    ...(blok.horizontal_alignment && getAlignedMarginStyle(blok.horizontal_alignment)),
  } as const;

  return (
    <Box sx={containerStyle} {...storyblokEditable(blok)} data-test-id="video-container">
      <VideoPlayer
        url={blok.video.url}
        autoplay={blok.auto_play}
        useNativeControls={blok.native_controls}
        useCustomPlayControl={!blok.hide_custom_play_control}
        useCustomMuteControl={!blok.hide_custom_mute_control}
        useCustomFullscreenControl={!blok.hide_custom_fullscreen_control}
        analyticsEventsData={{ origin_component: 'cms_video' }}
      />
    </Box>
  );
}
