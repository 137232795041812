import { Box } from '@chakra-ui/react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { EXTENDED_SIZES, IMAGE_POSITION } from '../../constants/enums';
import { IStoryblokCarouselPageSection } from '../../types/storyblokComponents';
import Carousel from '../carousels/Carousel';

interface Props {
  blok: IStoryblokCarouselPageSection;
}

export default function StoryblokCarouselPageSection(props: Props) {
  const { blok } = props;

  return (
    <Box position="relative" width="full" {...storyblokEditable(blok)}>
      <Carousel
        columns={blok.columns.map((column) => {
          return {
            content: column.blocks.map((block, index) => (
              <StoryblokComponent blok={block} key={block._uid} />
            )),
            imageUrl: column.image.filename,
            imageAlt: column.image.alt,
            imageOverlay: column.image_overlay,
          };
        })}
        height={blok.height || EXTENDED_SIZES.MD}
        columnsPerSlide={parseInt(blok.columns_per_slide) || 1}
        hasBackgroundImage={
          !blok.image_position || blok.image_position === IMAGE_POSITION.BACKGROUND
        }
        hasAlignedImage={blok.image_position === IMAGE_POSITION.INLINE}
        autoPlay={blok.auto_play}
        hideArrows={blok.hide_buttons}
        dotsColour={blok.dots_colour}
        horizontalAlignment={blok.horizontal_alignment}
        verticalAlignment={blok.vertical_alignment}
      />
    </Box>
  );
}
