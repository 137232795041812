import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputLeftElement,
  List,
  ListItem,
} from '@chakra-ui/react';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const resultListStyle = {
  background: 'gray.200',
  position: 'absolute',
  paddingY: 4,
  zIndex: 2,
  width: '100%',
} as const;

const resultListItemStyle = {
  listStyleImage: 'none !important',
  paddingLeft: 0,
  marginBottom: 0,
  marginRight: 4,
  paddingY: 2,
  cursor: 'pointer',
} as const;

interface Props {
  id: string;
  inputLabel: string;
  initialValue: string;
  updateInputValueState: Dispatch<SetStateAction<string>>;
  updateSelectedPlaceState: Dispatch<SetStateAction<google.maps.places.PlaceResult | null>>;
}

export default function LocationInput(props: Props) {
  const { id, inputLabel, initialValue, updateInputValueState, updateSelectedPlaceState } = props;
  const [placeInputValue, setPlaceInputValue] = useState<string>(initialValue);

  // Init autocomplete places service
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({ debounce: 300 }); // debounce sets how often the places api is called for predictions (in ms)

  function onInputChange(event: ChangeEvent<HTMLInputElement>): void {
    const inputValue = event.target.value;

    getPlacePredictions({ input: inputValue });
    setPlaceInputValue(inputValue);
    updateInputValueState(inputValue);
  }

  function placeSelected(place: google.maps.places.AutocompletePrediction): void {
    setPlaceInputValue(place.description);
    placesService?.getDetails(
      {
        placeId: place.place_id,
      },
      (details) => {
        if (!details) return;
        updateSelectedPlaceState(details);
      },
    );
    getPlacePredictions({ input: '' });
  }

  return (
    <Box>
      {/* Place search input - changes trigger auto predictions to load */}
      <FormControl variant="floating" id={id}>
        <InputLeftElement pointerEvents="none">
          <FontAwesomeIcon icon={faLocationDot} size="lg" />
        </InputLeftElement>
        <Input
          type="text"
          fontSize={{ base: 'sm', md: 'md' }}
          value={placeInputValue}
          placeholder=" "
          variant="flushed"
          onChange={(event) => onInputChange(event)}
        />
        <FormLabel>{inputLabel}</FormLabel>
        {/* List of loaded places from auto prediction on users input */}
        {!isPlacePredictionsLoading && placePredictions.length > 0 && (
          <List sx={{ ...resultListStyle }}>
            {placePredictions.map((place: google.maps.places.AutocompletePrediction, index) => (
              <ListItem
                sx={resultListItemStyle}
                key={`place_${index}`}
                onClick={() => placeSelected(place)}
              >
                {place.description}
              </ListItem>
            ))}
          </List>
        )}
      </FormControl>
    </Box>
  );
}
