import { getAnalytics, logEvent } from 'firebase/analytics';
import { mixpanelEvent } from '../pages/_app';

export function analyticsEvent(event: string, params?: {}): void {
  const analytics = getAnalytics();
  logEvent(analytics, event, params!);
  mixpanelEvent(event, params);
}

export default analyticsEvent;
