import { ReactElement } from 'react';
import useIsBackstageRoute from '../../hooks/isBackstageRoute';
import BackstageFooter from './BackstageFooter';
import BackstageNav from './BackstageNav';
import MarketingFooter from './MarketingFooter';
import MarketingNav from './MarketingNav';

export default function Layout({ children }: { children: ReactElement }) {
  const isBackstageRoute = useIsBackstageRoute();

  return (
    <>
      {isBackstageRoute ? <BackstageNav /> : <MarketingNav />}
      <main>{children}</main>
      {isBackstageRoute ? <BackstageFooter /> : <MarketingFooter />}
    </>
  );
}
