import { Box, Button, IconButton, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { ReactNode, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  COLOURS,
  EXTENDED_SIZES,
  HORIZONTAL_ALIGNMENTS,
  IMAGE_POSITION,
  OVERLAYS,
  VERTICAL_ALIGNMENTS,
} from '../../constants/enums';
import { getAlignedContainerStyle, getVerticalAlignmentStyle } from '../../styles/global';
import { containerPaddingX } from '../../styles/theme/components';

export interface Props {
  columns: {
    content: ReactNode[];
    imageUrl?: string;
    imageAlt?: string;
    imageOverlay?: OVERLAYS;
  }[];
  columnsPerSlide?: number;
  columnsToScroll?: number;
  height?: EXTENDED_SIZES;
  hasBackgroundImage?: boolean;
  hasAlignedImage?: boolean;
  hasFluidScroll?: boolean;
  infinite?: boolean;
  fade?: boolean;
  autoPlay?: boolean;
  hideArrows?: boolean;
  buttonsColor?: string;
  hideDots?: boolean;
  dotsColour?: COLOURS;
  horizontalAlignment?: HORIZONTAL_ALIGNMENTS;
  verticalAlignment?: VERTICAL_ALIGNMENTS;
}

export default function Carousel(props: Props) {
  const {
    columns,
    columnsPerSlide = 1,
    columnsToScroll = 1,
    hasBackgroundImage = false,
    hasAlignedImage = false,
    hasFluidScroll = false,
    height,
    fade = true,
    infinite = true,
    autoPlay = false,
    hideArrows = false,
    hideDots = false,
    dotsColour = COLOURS.PINK,
    buttonsColor = 'gray',
    horizontalAlignment = HORIZONTAL_ALIGNMENTS.CENTER,
    verticalAlignment = VERTICAL_ALIGNMENTS.CENTER,
  } = props;

  const [slider, setSlider] = useState<Slider | null>(null);
  const theme = useTheme();

  const t = useTranslations('Global.carousel');

  const isPageSection = hasBackgroundImage || hasAlignedImage;
  const imagePosition = hasBackgroundImage
    ? IMAGE_POSITION.BACKGROUND
    : hasAlignedImage
    ? IMAGE_POSITION.INLINE
    : null;

  const columnStyle = {
    ...getAlignedContainerStyle('column', horizontalAlignment, VERTICAL_ALIGNMENTS.CENTER),
    paddingX: columnsPerSlide > 1 && !hasFluidScroll ? 6 : 0,
    minHeight:
      height === EXTENDED_SIZES.XS
        ? { md: '10rem', xl: '10rem', '2xl': '10rem', '3xl': '15rem' }
        : height === EXTENDED_SIZES.SM
        ? { md: '30rem', xl: '35rem', '2xl': '40rem', '3xl': '50rem' }
        : height === EXTENDED_SIZES.MD
        ? { md: '42rem', xl: '40rem', '2xl': '45rem', '3xl': '55rem' }
        : height === EXTENDED_SIZES.LG
        ? { md: '44rem', xl: '45rem', '2xl': '50rem', '3xl': '60rem' }
        : height === EXTENDED_SIZES.XL
        ? '100vh'
        : '0px',
    flex: 1,

    p: {
      maxWidth: 'unset ',
    },
  } as const;

  const imageContainerStyle = {
    position: { base: 'relative', md: hasBackgroundImage && 'absolute' },
    width: { base: '100%', md: hasAlignedImage && '38%', lg: hasAlignedImage && '50%' },
    height: { base: '80vw', sm: '65vw', md: '100%' },
    zIndex: -1,
  } as const;

  const pageSectionColumnStyle = {
    ...columnStyle,
    display: { md: isPageSection ? 'flex !important' : 'inline-block' },
    flexDirection: 'row-reverse',
  } as const;

  const pageSectionColumnContentStyle = {
    flex: { md: hasAlignedImage && 1 },
    width: '100%',
    alignSelf: getVerticalAlignmentStyle(verticalAlignment),
    paddingX: {
      base: containerPaddingX.base,
      md: hideArrows ? containerPaddingX.md : `calc(${containerPaddingX.md} + 5rem)`,
      xl: hideArrows ? containerPaddingX.md : `calc(${containerPaddingX.md} + 6rem)`,
      '2xl': hideArrows ? containerPaddingX['2xl'] : `calc(${containerPaddingX.md} + 6rem)`,
      '3xl': hideArrows ? containerPaddingX['3xl'] : `calc(${containerPaddingX.md} + 10rem)`,
    },
    paddingRight: { md: hasAlignedImage && 8 },
    paddingTop: { base: 14, md: 20, xl: 28 },
    paddingBottom: { base: 2, md: 24, xl: 28 },
  } as const;

  // Get hex colour from theme as it doesn't work directly in CSS border value
  // e.g pink.500 -> #FF002E
  const splitDotsColor = dotsColour.split('.');
  const dotsColor =
    splitDotsColor.length === 1
      ? theme.colors[splitDotsColor[0]]
      : theme.colors[splitDotsColor[0]][splitDotsColor[1]];

  const dotStyle = {
    border: `0.0625rem solid ${dotsColor} !important`,
  } as const;

  const dotActiveStyle = {
    background: dotsColor,
  } as const;

  const arrowStyle = {
    position: 'absolute',
    transform: 'translate(0%, -50%)',
    zIndex: 2,
    ...(isPageSection
      ? {
          top: { base: '40vw', sm: '32.5vw', md: '50%' },
          marginX: { base: 5, md: 10, xl: 12, '3xl': 20 },
        }
      : { top: '50%', display: { base: 'none', md: 'block' } }),
  } as const;

  const overlayStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const settings = {
    dots: !hideDots,
    customPaging: function (i: any) {
      return (
        <Button
          _before={dotActiveStyle}
          sx={dotStyle}
          variant="unstyled"
          data-test-id={`carousel-dot-${i}`}
        ></Button>
      );
    },
    className: `${isPageSection && 'as-page-section'} ${
      hasFluidScroll && columnsToScroll > 1 && 'as-scroll'
    } ${!hideDots && 'with-dots'} ${
      !hideArrows && !hasFluidScroll && 'with-arrows'
    } align-${horizontalAlignment}`,
    arrows: false,
    infinite: infinite,
    autoplay: autoPlay,
    speed: 500,
    autoplaySpeed: 5000,
    rows: 1,
    variableWidth: hasFluidScroll,
    slidesToScroll: isMobile ? 1 : columnsToScroll,
    slidesPerRow: columnsPerSlide,
    fade: fade,

    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesPerRow: Math.round(columnsPerSlide / 2),
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesPerRow: columnsPerSlide,
        },
      },
    ],
  };

  return (
    <Box position="relative" width="full">
      {!hideArrows && columns.length > 1 && (
        <>
          <IconButton
            icon={
              <FontAwesomeIcon
                icon={faChevronLeft}
                color={buttonsColor === 'pink' ? 'white' : 'black'}
              />
            }
            sx={{ ...arrowStyle, left: 0 }}
            aria-label={t('prevButton')}
            rounded="full"
            colorScheme={buttonsColor}
            onClick={() => slider?.slickPrev()}
            data-test-id="carousel-prev-button"
          />

          <IconButton
            icon={
              <FontAwesomeIcon
                icon={faChevronRight}
                color={buttonsColor === 'pink' ? 'white' : 'black'}
              />
            }
            sx={{ ...arrowStyle, right: 0 }}
            aria-label={t('nextButton')}
            rounded="full"
            colorScheme={buttonsColor}
            onClick={() => slider?.slickNext()}
            data-test-id="carousel-next-button"
          />
        </>
      )}

      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {!isPageSection &&
          columns.map((column, index) => (
            <Box key={`column-${index}`} sx={columnStyle} data-test-id="carousel-column">
              {column.content?.map((block, index) => block)}
            </Box>
          ))}

        {isPageSection &&
          columns.map((column, index) => (
            <Box
              sx={pageSectionColumnStyle}
              key={`column-container-${index}`}
              position="relative"
              data-test-id="carousel-column-container"
            >
              {column.imageUrl && (
                <Box
                  sx={imageContainerStyle}
                  data-test-id={`carousel-column-${imagePosition}-image`}
                >
                  <Image
                    src={column.imageUrl}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="top"
                    quality={100}
                    alt={column.imageAlt}
                    data-test-id="image"
                  />
                  {column.imageOverlay && (
                    <Box
                      sx={{ ...overlayStyle, backgroundImage: column.imageOverlay }}
                      data-test-id="overlay"
                    />
                  )}
                </Box>
              )}
              <Box
                key={`column-${index}`}
                sx={pageSectionColumnContentStyle}
                data-test-id="carousel-column"
              >
                {column.content?.map((block, index) => block)}
              </Box>
            </Box>
          ))}
      </Slider>
    </Box>
  );
}
