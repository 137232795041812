import { apiPlugin, storyblokInit } from '@storyblok/react';
import StoryblokBossCourseCard from '../components/storyblok/StoryblokBossCourseCard';
import StoryblokButton from '../components/storyblok/StoryblokButton';
import StoryblokCarousel from '../components/storyblok/StoryblokCarousel';
import StoryblokCarouselPageSection from '../components/storyblok/StoryblokCarouselPageSection';
import StoryblokChoreographerCarousel from '../components/storyblok/StoryblokChoreographerCarousel';
import StoryblokFaqs from '../components/storyblok/StoryblokFaqs';
import StoryblokHero from '../components/storyblok/StoryblokHero';
import StoryblokImage from '../components/storyblok/StoryblokImage';
import StoryblokInstagramEmbed from '../components/storyblok/StoryblokInstagramEmbed';
import StoryblokPage from '../components/storyblok/StoryblokPage';
import StoryblokPageSection from '../components/storyblok/StoryblokPageSection';
import StoryblokQuote from '../components/storyblok/StoryblokQuote';
import StoryblokRichtext from '../components/storyblok/StoryblokRichtext';
import StoryblokRoutinePreviewCard from '../components/storyblok/StoryblokRoutinePreviewCard';
import StoryblokRow from '../components/storyblok/StoryblokRow';
import StoryblokSpacer from '../components/storyblok/StoryblokSpacer';
import StoryblokTwoColumnPageSection from '../components/storyblok/StoryblokTwoColumnPageSection';
import StoryblokVideo from '../components/storyblok/StoryblokVideo';

const storyblok = storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  // apiOptions: {  },
  use: [apiPlugin],
  components: {
    page: StoryblokPage,
    hero: StoryblokHero,
    page_section: StoryblokPageSection,
    two_column_page_section: StoryblokTwoColumnPageSection,
    row: StoryblokRow,
    spacer: StoryblokSpacer,
    richtext: StoryblokRichtext,
    button: StoryblokButton,
    image: StoryblokImage,
    video: StoryblokVideo,
    quote: StoryblokQuote,
    instagram_embed: StoryblokInstagramEmbed,
    faqs: StoryblokFaqs,
    carousel: StoryblokCarousel,
    carousel_page_section: StoryblokCarouselPageSection,
    choreographer_carousel: StoryblokChoreographerCarousel,
    routine_preview_card: StoryblokRoutinePreviewCard,
    boss_course_card: StoryblokBossCourseCard,
  },
});

export default storyblok;
