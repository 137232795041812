import { Box } from '@chakra-ui/react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { NextSeo } from 'next-seo';
import { IStoryblokHero, IStoryblokPage } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokPage;
}

export default function StoryblokPage(props: Props) {
  const { blok } = props;

  return (
    <>
      <NextSeo
        title={blok.seo_title && blok.seo_title}
        description={blok.seo_description && blok.seo_description}
        openGraph={{
          ...(blok.seo_image?.filename && {
            images: [
              {
                url: blok.seo_image.filename,
                width: 1200,
                height: 800,
                alt: blok.seo_image.alt,
              },
            ],
          }),
        }}
      />
      <Box {...storyblokEditable(blok)}>
        {blok.body?.map((nestedBlok: IStoryblokHero) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </Box>
    </>
  );
}
