import { Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const linkStyle = {
  display: 'block',
} as const;

interface Props {
  title: string;
  href: string;
  target?: string;
}

export default function FooterLink(props: Props) {
  const { href, title, target = '_self' } = props;

  return (
    <NextLink href={href} passHref>
      <Link sx={linkStyle} target={target} fontSize="2xl">
        {title}
      </Link>
    </NextLink>
  );
}
