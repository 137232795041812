import { Box, Container, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import { COOKIE_MODAL_OPENED } from '../../constants/events';
import { BASE_URL } from '../../constants/variables';
import { useAppDispatch } from '../../hooks/store';
import { openCookieModal } from '../../store/modalsSlice';
import analyticsEvent from '../../utils/logEvent';
import FooterLink from './FooterLink';
import SocialMediaLinks from './SocialMediaLinks';

const footerContainerStyle = {
  background: 'white',
  color: 'black',
} as const;

const linksContainerStyle = {
  flexDirection: { base: 'column', sm: 'row' },
  flexWrap: 'wrap',
  gap: { base: 4, sm: 7, md: 12 },
  justifyContent: 'center',
  textAlign: 'center',
} as const;

const socialsContainerStyle = {
  maxWidth: '27.5rem',
  marginY: 8,
} as const;

const policiesStyle = {
  gap: { base: 8, md: 12 },
} as const;

const copyrightStyle = {
  textAlign: 'center',
  marginTop: 12,
} as const;

export default function BackstageFooter() {
  const t = useTranslations('Global');
  const dispatch: any = useAppDispatch();

  function onClickOpenCookieSettings() {
    analyticsEvent(COOKIE_MODAL_OPENED, { origin_component: 'marketing_footer' });
    dispatch(openCookieModal());
  }

  return (
    <Box sx={footerContainerStyle}>
      <Container size="md" centerContent>
        <Heading as="h2" size="h-lg" color="pink.500" mb={8}>
          {t('empowerTitle')}
        </Heading>
        <Flex sx={linksContainerStyle}>
          <FooterLink href={`${BASE_URL}contact-us`} title={t('footer.support.contact')} />
          {/* <FooterLink href={BASE_URL} title={t('footer.support.faq')} /> */}
          <FooterLink
            href="https://blog.schoolofsos.com/"
            title={t('footer.hq.blog')}
            target="_blank"
          />
          {/* <FooterLink href={BASE_URL} title={t('footer.hq.herstory')} /> */}
        </Flex>
        <Box sx={socialsContainerStyle}>
          <SocialMediaLinks />
        </Box>
        <Flex sx={policiesStyle}>
          <NextLink href={`${BASE_URL}privacy-policy`} passHref>
            <Link data-test-id="privacy-policy-link">{t('footer.support.privacy')}</Link>
          </NextLink>
          <Link onClick={() => onClickOpenCookieSettings()} data-test-id="cookie-settings">
            {t('cookieBanner.settingsButton')}
          </Link>
        </Flex>
        <Text sx={copyrightStyle}>{t('footer.copyright')}</Text>
      </Container>
    </Box>
  );
}
