import { BossCourse } from '../types/bossCourses';
import { BossClass } from '../types/classes';

// Test class constant used to mock an eventbrite class set in env vars - usually a test class in the eventbrite test account
// Allows loading the production list of classes, but load every widget as this test event
export const testBossClass: BossClass = {
  bossName: 'SOS Amanda // Nicholson School of Dance',
  start: '2022-10-14T10:30:00Z',
  end: '2022-10-14T11:30:00Z',
  address: {
    address1: '10 Minerva Works',
    address2: '158 Fazeley Street',
    city: 'Birmingham',
    region: 'England',
    postalCode: 'B5 5RT',
    country: 'GB',
    latitude: '52.47905',
    longitude: '-1.882741',
    localizedAddressDisplay: '10 Minerva Works 158 Fazeley Street, Birmingham, B5 5RT',
    localizedAreaDisplay: 'Birmingham',
    localizedMultiLineAddressDisplay: [
      '10 Minerva Works',
      '158 Fazeley Street',
      'Birmingham',
      'B5 5RT',
    ],
  },
  distance: 3.024,
  eventId: process.env.NEXT_PUBLIC_EVENTBRITE_TEST_EVENT_ID || '',
  eventHref: process.env.NEXT_PUBLIC_EVENTBRITE_TEST_EVENT_HREF || '',
};

export const testBossCourse: BossCourse = {
  title: 'Digital SOS instructor training course',
  image: 'https://a.storyblok.com/f/153574/1200x801/f9f6c482cc/promo-1200x800.jpg',
  dates: '27th & 28th August 2022',
  times: '10am-6pm (both days)',
  location: 'Online course through Zoom',
  priceDescription: 'Payment plan available',
  price: 210,
  eventId: process.env.NEXT_PUBLIC_EVENTBRITE_TEST_EVENT_ID || '', // reuse test class instead of course
  eventHref: process.env.NEXT_PUBLIC_EVENTBRITE_TEST_EVENT_HREF || '',
};
