import { Button } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { SUBSCRIBE_MODAL_PAGES } from '../../constants/enums';
import { SUBSCRIBE_MODAL_DETAILS_VIEWED, SUBSCRIBE_MODAL_OPENED } from '../../constants/events';
import { BACKSTAGE_URL } from '../../constants/variables';
import { useAppDispatch } from '../../hooks/store';
import { openSubscribeModal } from '../../store/modalsSlice';
import { IStoryblokButton } from '../../types/storyblokComponents';
import analyticsEvent from '../../utils/logEvent';

interface Props {
  blok: IStoryblokButton;
}

export default function StoryblokButton(props: Props) {
  const { blok } = props;
  const [url, setUrl] = useState<string>('/');
  const [scrollToContainer, setScrollToContainer] = useState<string | null>(null);
  const [colorScheme, setColorScheme] = useState<string>('pink');
  const [variant, setVariant] = useState<string>('solid');
  const [size, setSize] = useState<string>('md');
  const dispatch: any = useAppDispatch();

  const externalLink = url.match(/^(https?:)?\/\//);

  useEffect(() => {
    const linkUrl = blok.link.url;

    if (linkUrl) {
      if (linkUrl?.includes('/backstage') && BACKSTAGE_URL) {
        setUrl(linkUrl.replace('/backstage', BACKSTAGE_URL));
      } else if (linkUrl?.startsWith('#') && linkUrl.length > 1) {
        setScrollToContainer(linkUrl);
      } else {
        setUrl(linkUrl);
      }
    }

    const splitColour = blok.colour.split('.')[0];
    const colour = splitColour || blok.colour;

    colour && setColorScheme(colour);

    blok.variant && setVariant(blok.variant);
    blok.size && setSize(blok.size);
  }, [url, blok]);

  // Custom button click functionality for scrolling to nth container element on the page
  // Used if button url starts with #
  function scrollOnClick() {
    if (!scrollToContainer) return;

    if (scrollToContainer === '#open-subscribe') {
      const eventOrigin = 'button';
      analyticsEvent(SUBSCRIBE_MODAL_OPENED, { origin_component: eventOrigin });
      analyticsEvent(SUBSCRIBE_MODAL_DETAILS_VIEWED, { origin_component: eventOrigin });
      dispatch(openSubscribeModal(SUBSCRIBE_MODAL_PAGES.DETAILS));
    } else {
      // Use #n to scroll to nth container on the page
      const scrollToValue = scrollToContainer.split('#')[1];
      const scrollToElement =
        document.querySelectorAll('.chakra-container')[parseInt(scrollToValue)];
      scrollToElement?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }

  // Scroll to container link - url starts with #
  if (scrollToContainer) {
    return (
      <Button
        my={4}
        colorScheme={colorScheme}
        size={size}
        variant={variant}
        onClick={scrollOnClick}
      >
        {blok.text}
      </Button>
    );
  }

  // External link - use button styles on anchor element and open in new tab
  if (externalLink) {
    return (
      <NextLink href={url} passHref>
        <Button
          as="a"
          target="_blank"
          my={4}
          colorScheme={colorScheme}
          size={size}
          variant={variant}
        >
          {blok.text}
        </Button>
      </NextLink>
    );
  }

  // Internal link
  return (
    <NextLink href={url} passHref>
      <Button my={4} colorScheme={colorScheme} size={size} variant={variant}>
        {blok.text}
      </Button>
    </NextLink>
  );
}
