import { ENVIRONMENTS } from '../constants/enums';
import { testBossClass } from '../constants/testClass';
import { BossCourse } from '../types/bossCourses';
import { BossClass } from '../types/classes';

export function loadEventbriteClassButtonWidget(
  bossClass: BossClass,
  bookingCompletedFunction: (orderId: string, bossClass: BossClass) => void,
) {
  // For dev/staging, load every button widget as the test class event (testBossClass) to prevent booking production events
  const widgetEventId =
    process.env.NEXT_PUBLIC_ENV === ENVIRONMENTS.PRODUCTION
      ? bossClass.eventId
      : testBossClass.eventId;

  (window as any).EBWidgets.createWidget({
    widgetType: 'checkout',
    eventId: widgetEventId,
    modal: true,
    modalTriggerElementId: `eventbrite-widget-modal-trigger-${bossClass.eventId}`,
    onOrderComplete: (result?: { orderId: string }) =>
      result && result.orderId && bookingCompletedFunction(result.orderId, bossClass),
  });
}

export function loadEventbriteBossCourseButtonWidget(
  bossCourse: BossCourse,
  bookingCompletedFunction: (orderId: string) => void,
) {
  // For dev/staging, load every button widget as the test event (testBossCourse) to prevent booking production events
  const widgetEventId =
    process.env.NEXT_PUBLIC_ENV === ENVIRONMENTS.PRODUCTION
      ? bossCourse.eventId
      : testBossClass.eventId;

  (window as any).EBWidgets.createWidget({
    widgetType: 'checkout',
    eventId: widgetEventId,
    modal: true,
    modalTriggerElementId: `eventbrite-widget-modal-trigger-${bossCourse.eventId}`,
    onOrderComplete: (result?: { orderId: string }) =>
      result && result.orderId && bookingCompletedFunction(result.orderId),
  });
}
