import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer';
import { RichTextConfig } from '../../config/storyblokRichtext';
import { IStoryblokQuote } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokQuote;
}

export default function StoryblokQuote(props: Props) {
  const { blok } = props;

  return (
    <Box {...storyblokEditable(blok)} data-test-id="quote">
      {render(blok.quote, RichTextConfig)}
      {render(blok.cite, RichTextConfig)}
    </Box>
  );
}
