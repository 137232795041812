import { poppinsFont } from './fonts';

const stackedMargin = {
  '&:first-child': {
    marginTop: '0 !important',
  },
  '&:last-child': {
    marginBottom: '0 !important',
  },
};

export const styles = {
  global: {
    html: {
      // NOTE: mid sized screens (between 768px and 1536px) will use 15px as the base size.
      // Where you might expect an element to be 2rem = 32px, on smaller screens this will be 2rem = 30px.
      fontSize: { base: '16px', sm: '15px', '2xl': '16px', '3xl': '20px' },
    },
    body: {
      background: 'gray.900',
      color: 'white',
    },
    a: {
      textDecoration: 'underline',
      ...stackedMargin,

      '&.chakra-button': {
        textDecoration: 'none',
      },
    },
    iframe: {
      width: '100%',
      height: '100%',
    },
    p: {
      marginBottom: 3,
      ...stackedMargin,
    },
    button: {
      ...stackedMargin,
    },
    image: {
      ...stackedMargin,
    },
    ul: {
      '>li': {
        listStyleImage: 'url("../icons/crown_white.svg")',
        textAlign: 'left',
        paddingLeft: 2,
        marginBottom: 3,
        marginLeft: 10,
      },
    },
    ol: {
      marginLeft: 4,

      '> li': {
        paddingLeft: 2,
        marginBottom: 3,
        '::marker': {
          fontWeight: 'bold',
        },
      },
    },
    '--datepicker__selected-color': 'pink.500',
    '.react-datepicker': {
      fontFamily: poppinsFont,
    },
    '.react-datepicker__input-container input': {
      height: 10,
      width: '100%',
      paddingLeft: 10,
    },
    '.background-section-react-player': {
      left: '50%',
      minHeight: '100%',
      minWidth: '100%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      top: '50%',
      width: '177.77777778vh !important',
      height: { md: '56.25vw !important' },
    },
    '.background-preview-react-player': {
      left: '50%',
      minHeight: '100%',
      minWidth: '100%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      top: '50%',
      height: '56.25vw !important',
      width: { md: 'calc(40rem / 0.5625) !important' },
    },
  },
};
