import { EXTENDED_SIZES, HORIZONTAL_ALIGNMENTS, VERTICAL_ALIGNMENTS } from '../constants/enums';

export function getVerticalAlignmentStyle(vertical_alignment?: VERTICAL_ALIGNMENTS) {
  return vertical_alignment === VERTICAL_ALIGNMENTS.TOP
    ? 'flex-start'
    : vertical_alignment === VERTICAL_ALIGNMENTS.BOTTOM
    ? 'flex-end'
    : 'center';
}

export function getHorizontalAlignmentStyle(horizontal_alignment: HORIZONTAL_ALIGNMENTS) {
  return horizontal_alignment === HORIZONTAL_ALIGNMENTS.LEFT
    ? 'flex-start'
    : horizontal_alignment === HORIZONTAL_ALIGNMENTS.RIGHT
    ? 'flex-end'
    : 'center';
}

export function getAlignedContainerStyle(
  flexDirection: 'column' | 'row',
  horizontal_alignment: HORIZONTAL_ALIGNMENTS,
  vertical_alignment?: VERTICAL_ALIGNMENTS,
) {
  return {
    display: 'flex',
    flexDirection: flexDirection,
    alignItems: getHorizontalAlignmentStyle(horizontal_alignment),
    justifyContent: getVerticalAlignmentStyle(vertical_alignment),
    textAlign: horizontal_alignment || 'center',
  };
}

export function getAlignedMarginStyle(alignment: HORIZONTAL_ALIGNMENTS) {
  return {
    marginLeft: alignment === HORIZONTAL_ALIGNMENTS.LEFT ? 0 : 'auto',
    marginRight: alignment === HORIZONTAL_ALIGNMENTS.RIGHT ? 0 : 'auto',
  };
}

export function getImageSizeStyle(width: EXTENDED_SIZES) {
  return {
    maxWidth: '100%',
    width:
      width === EXTENDED_SIZES.XS
        ? { base: '3.475rem', '2xl': '3.75rem' }
        : width === EXTENDED_SIZES.SM
        ? { base: '5rem', md: '6.475rem', '2xl': '7.5rem' }
        : width === EXTENDED_SIZES.MD
        ? { base: '15.625rem', md: '20.5rem', '2xl': '23.5rem' }
        : width === EXTENDED_SIZES.LG
        ? { base: '31.25rem', md: '40rem', '2xl': '46.875rem' }
        : '100%',
    marginY:
      width === EXTENDED_SIZES.XS
        ? 3
        : width === EXTENDED_SIZES.SM
        ? 3
        : width === EXTENDED_SIZES.MD
        ? 4
        : width === EXTENDED_SIZES.LG
        ? 5
        : 6,
  };
}

export const resultListStyle = {
  marginTop: 0,
  paddingTop: 4,
  paddingRight: 0,
  paddingLeft: '3.125rem',
  maxHeight: '24rem',
  overflowY: 'scroll',
  listStyleImage: 'none !important',
  '::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

export const resultListItemStyle = {
  paddingLeft: 0,
  marginLeft: 0,
  paddingBottom: 2,
  borderBottom: '0.075rem solid',
  borderBottomColor: 'gray.300',
  listStyleImage: 'none !important',

  '&:last-of-type': {
    borderBottom: 'none',
  },
} as const;

export const backgroundImageContainerStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -1,
} as const;
