import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CookiesSlice {
  performanceAccepted: boolean | null;
  analyticsAccepted: boolean | null;
  marketingAccepted: boolean | null;
  settingsSet: boolean;
  authModalViewed: boolean;
}

// Cookies slice keeps track of if cookie settings have been set, and what the settings are so we can restrict some functionality accordingly.
export const initialState: CookiesSlice = {
  performanceAccepted: null,
  analyticsAccepted: null,
  marketingAccepted: null,
  settingsSet: false,
  authModalViewed: false,
};

const slice = createSlice({
  name: 'cookies',
  initialState: initialState,
  reducers: {
    clearCookiesSlice() {
      return initialState;
    },

    updateCookies(
      state,
      action: PayloadAction<{
        performanceAccepted: boolean;
        analyticsAccepted: boolean;
        marketingAccepted: boolean;
        settingsSet: boolean;
      }>,
    ) {
      state.performanceAccepted = action.payload.performanceAccepted;
      state.analyticsAccepted = action.payload.analyticsAccepted;
      state.marketingAccepted = action.payload.marketingAccepted;
      state.settingsSet = action.payload.settingsSet;
    },

    setAuthModalViewed(state) {
      state.authModalViewed = true;
    },
  },
});

const { actions, reducer } = slice;
export const { clearCookiesSlice, updateCookies, setAuthModalViewed } = actions;
export default reducer;
