import { Button, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const linkStyle = {
  display: 'block',
  padding: 2.5,
  paddingTop: 3,
  fontWeight: 'extrabold',
  textTransform: 'uppercase',
  textAlign: 'left',
  height: 'auto',

  _hover: {
    textDecoration: 'none',
  },
} as const;

const activeLinkStyle = {
  ...linkStyle,
  color: 'pink.500',

  _after: {
    content: '""',
    display: { base: 'none', md: 'block' },
    position: 'relative',
    top: { md: '1rem', '2xl': '1.75rem' },
    marginTop: '-0.25rem',
    borderBottom: '0.25rem solid',
    borderColor: 'pink.500',
  },
} as const;

export interface NavigationItem {
  title: string;
  href?: string;
  isActive: boolean;
  onClick?: () => void;
  dataTestId?: string;
}

interface Props {
  link: NavigationItem;
  fontSize?: string;
  closeNav?: () => void;
}

export default function NavLink(props: Props) {
  const { link, fontSize = 'md', closeNav } = props;

  function onClickLink() {
    if (closeNav) {
      closeNav();
    }
    if (link.onClick) {
      link.onClick();
    }
  }

  if (link.onClick) {
    return (
      <Button
        sx={linkStyle}
        variant="unstyled"
        aria-label={link.title}
        onClick={onClickLink}
        fontSize={fontSize}
        data-test-id={link.dataTestId ? link.dataTestId : 'edit-profile'}
      >
        {link.title}
      </Button>
    );
  }

  return (
    <NextLink href={link.href || '/'} passHref>
      <Link sx={link.isActive ? activeLinkStyle : linkStyle} fontSize={fontSize} onClick={closeNav}>
        {link.title}
      </Link>
    </NextLink>
  );
}
