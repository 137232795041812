import { IconButton } from '@chakra-ui/react';
import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NextLink from 'next/link';
import { SOCIAL_MEDIA } from '../../constants/enums';

interface Props {
  href: string;
  socialMedia: SOCIAL_MEDIA;
}

export default function SocialMediaLink(props: Props) {
  const { href, socialMedia } = props;

  const icon =
    socialMedia === SOCIAL_MEDIA.FACEBOOK
      ? faFacebook
      : socialMedia === SOCIAL_MEDIA.INSTAGRAM
      ? faInstagram
      : socialMedia === SOCIAL_MEDIA.TIKTOK
      ? faTiktok
      : faYoutube;

  const linkStyle = {
    display: 'flex',
  } as const;

  return (
    <NextLink href={href} passHref>
      <IconButton
        as="a"
        target="_blank"
        sx={linkStyle}
        icon={<FontAwesomeIcon icon={icon} size="2x" />}
        aria-label={socialMedia}
        variant="unstyled"
      />
    </NextLink>
  );
}
