import { Box, Flex, IconButton } from '@chakra-ui/react';

import {
  faExpandWide,
  faPause,
  faPlay,
  faVolume,
  faVolumeOff,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { contentVideoEvents, VideoEvents } from '../../constants/events';
import useFullscreen from '../../hooks/useFullscreen';
import { IrlRoutineEventData } from '../../types/routines';
import analyticsEvent from '../../utils/logEvent';

const videoAbsoluteStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
} as const;

interface Props {
  url: string;
  previewImage?: string;
  display?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  loop?: boolean;
  isBackground?: boolean;
  isBackgroundPreview?: boolean;
  isFullscreenOnly?: boolean;
  fullscreenButtonLabel?: string;
  fullscreenButtonVariant?: 'outline' | 'unstyled' | 'solid';
  fullscreenButtonSize?: 'xs' | 'sm' | 'md' | 'lg';
  useNativeControls?: boolean;
  useCustomPlayControl?: boolean;
  useCustomMuteControl?: boolean;
  useCustomFullscreenControl?: boolean;
  analyticsEvents?: VideoEvents;
  analyticsEventsData?:
    | { [key: string]: string | number | Date | boolean }
    | IrlRoutineEventData
    | null;
}

export default function VideoPlayer(props: Props) {
  const {
    url,
    previewImage,
    display = true,
    autoplay = false,
    muted = false,
    loop = false,
    isBackground = false,
    isBackgroundPreview = false,
    isFullscreenOnly = false,
    fullscreenButtonLabel = null,
    fullscreenButtonVariant = 'solid',
    fullscreenButtonSize = 'lg',
    useNativeControls = false,
    useCustomMuteControl = true,
    useCustomPlayControl = true,
    useCustomFullscreenControl = true,
    analyticsEvents = contentVideoEvents,
    analyticsEventsData,
  } = props;
  const t = useTranslations('Global');
  const containerElementRef = useRef<HTMLDivElement>(null);

  const [videoMuted, setVideoMuted] = useState<boolean>(muted ? true : false);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(autoplay);
  const [videoStarted, setVideoStarted] = useState<boolean>(autoplay);

  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [videoPlayedData, setVideoPlayedData] = useState<{ played: number; playedSeconds: number }>(
    { played: 0, playedSeconds: 0 },
  );
  const [videoLoadedData, setVideoLoadedData] = useState<{ loaded: number; loadedSeconds: number }>(
    { loaded: 0, loadedSeconds: 0 },
  );

  const [isFullScreen, setIsVideoLoaded, enterFullscreen, exitFullscreen] = useFullscreen(
    containerElementRef,
    setVideoPlaying,
    isFullscreenOnly && useNativeControls,
  );

  const videoBackgroundStyle = {
    zIndex: -1,
  } as const;

  const videoContainerStyle = {
    position: 'relative',
    paddingTop: '56.25%',
    backgroundColor: 'black',
  } as const;

  const controlsContainerStyle = {
    gap: 2,
    color: 'black',
    position: 'absolute',
    bottom: 6,
    left: 6,
    zIndex: 2,
  } as const;

  useEffect(() => {
    if (!display) {
      setVideoPlaying(false);
    }
  }, [display]);

  function getVideoAnalyticsData(): {
    video_played_percentage: number;
    video_played_seconds: number;
    video_loaded_percentage: number;
    video_loaded_seconds: number;
  } {
    return {
      video_played_percentage: Math.round(videoPlayedData.played * 100) || 0,
      video_played_seconds: videoPlayedData.playedSeconds,
      video_loaded_percentage: Math.round(videoLoadedData.loaded * 100) || 0,
      video_loaded_seconds: videoLoadedData.loadedSeconds,
    };
  }

  function getAnalyticsData(): {
    [key: string]: boolean | string | number | Date;
  } {
    return {
      ...analyticsEventsData,
      video_duration_seconds: videoDuration,
      video_full_screen: isFullScreen,
      player_autoplay: autoplay,
      player_full_screen_only: isFullscreenOnly,
      player_native_controls: useNativeControls,
      player_custom_mute_control: useCustomMuteControl,
      player_custom_play_control: useCustomPlayControl,
      player_custom_full_screen_control: useCustomFullscreenControl,
    };
  }

  function onPlay(): void {
    setVideoPlaying(true);

    if (!videoStarted) {
      setVideoStarted(true);
    }
    analyticsEvent(analyticsEvents.played, {
      ...getAnalyticsData(),
      ...getVideoAnalyticsData(),
    });
  }

  function onPause(): void {
    setVideoPlaying(false);
    analyticsEvent(analyticsEvents.paused, {
      ...getAnalyticsData(),
      ...getVideoAnalyticsData(),
    });
  }

  function onEnded(): void {
    analyticsEvent(analyticsEvents.ended, { ...getAnalyticsData() });
  }

  return (
    <>
      <Box>
        <Box
          ref={containerElementRef}
          sx={isBackground ? videoBackgroundStyle : videoContainerStyle}
        >
          <ReactPlayer
            style={isBackground ? {} : videoAbsoluteStyle}
            className={
              isBackgroundPreview
                ? 'background-preview-react-player'
                : isBackground
                ? 'background-section-react-player'
                : ''
            }
            width="100%"
            height="100%"
            url={url}
            playing={videoPlaying}
            loop={loop ? true : false}
            volume={0.8}
            controls={useNativeControls}
            muted={videoMuted}
            playsinline={true}
            onReady={() => setIsVideoLoaded(true)}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
            onDuration={(duration) => setVideoDuration(duration)}
            onProgress={(data) => {
              setVideoPlayedData({ played: data.played, playedSeconds: data.playedSeconds });
              setVideoLoadedData({ loaded: data.loaded, loadedSeconds: data.loadedSeconds });
            }}
            progressInterval={5000}
            data-test-id="video-player"
          />
          {/* if a preview image is provided, show this image before the video has been played. 
          Preview image can't be used with native controls as the image covers the video, blocking access to controls */}
          {!!previewImage && !videoStarted && !useNativeControls && !autoplay && (
            <Image
              src={previewImage}
              alt={t('alt.previewImage')}
              layout="fill"
              objectFit="cover"
              objectPosition="top"
            />
          )}
          {!useNativeControls && (
            <Flex sx={controlsContainerStyle}>
              {useCustomPlayControl && (
                <IconButton
                  icon={
                    videoPlaying ? (
                      <FontAwesomeIcon icon={faPause} />
                    ) : (
                      <FontAwesomeIcon icon={faPlay} />
                    )
                  }
                  rounded="full"
                  variant="solid"
                  colorScheme="gray"
                  aria-label={t('playButton')}
                  onClick={() => setVideoPlaying(!videoPlaying)}
                  data-test-id="video-play-button"
                />
              )}
              {useCustomMuteControl && (
                <IconButton
                  icon={
                    videoMuted ? (
                      <FontAwesomeIcon icon={faVolumeOff} />
                    ) : (
                      <FontAwesomeIcon icon={faVolume} />
                    )
                  }
                  rounded="full"
                  aria-label={t('muteButton')}
                  variant="solid"
                  colorScheme="gray"
                  onClick={() => setVideoMuted(!videoMuted)}
                  data-test-id="video-mute-button"
                />
              )}
              {useCustomFullscreenControl && (
                <IconButton
                  icon={<FontAwesomeIcon icon={faExpandWide} />}
                  rounded="full"
                  variant="solid"
                  colorScheme="gray"
                  aria-label={t('fullscreenButton')}
                  onClick={isFullScreen ? exitFullscreen : enterFullscreen}
                  data-test-id="video-full-screen-button"
                />
              )}
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
}
