import { HStack } from '@chakra-ui/react';
import { SOCIAL_MEDIA } from '../../constants/enums';
import SocialMediaLink from '../ui/SocialMediaLink';

interface Props {
  spacing?: number;
}

export default function SocialMediaLinks(props: Props) {
  const { spacing = 4 } = props;

  return (
    <HStack spacing={spacing}>
      <SocialMediaLink
        href="https://www.instagram.com/schoolofsos"
        socialMedia={SOCIAL_MEDIA.INSTAGRAM}
      />
      <SocialMediaLink
        href="https://www.facebook.com/schoolofsos"
        socialMedia={SOCIAL_MEDIA.FACEBOOK}
      />
      <SocialMediaLink
        href="https://www.youtube.com/channel/UC75A-jNluNXkA8wE1IU2Yow"
        socialMedia={SOCIAL_MEDIA.YOUTUBE}
      />
      <SocialMediaLink
        href="https://www.tiktok.com/@schoolofsos"
        socialMedia={SOCIAL_MEDIA.TIKTOK}
      />
    </HStack>
  );
}
