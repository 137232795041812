import { Box, Button, Heading, Link, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect } from 'react';
import {
  BOOK_BOSS_COURSE_BOOKED,
  BOOK_BOSS_COURSE_MORE_INFO_CLICKED,
  BOOK_BOSS_COURSE_OPEN_MODAL,
} from '../../constants/events';
import { BossCourse } from '../../types/bossCourses';
import { loadEventbriteBossCourseButtonWidget } from '../../utils/eventbrite';
import analyticsEvent from '../../utils/logEvent';
import { serializeBossCourseEventData } from '../../utils/serialize/analyticsEventsData';

interface Props {
  bossCourse: BossCourse;
}

const cardStyle = {
  borderRadius: 4,
  background: 'white',
  color: 'black',
  overflow: 'hidden',
} as const;

export default function BossCourseCard(props: Props) {
  const { bossCourse } = props;

  const t = useTranslations('Global');

  useEffect(() => {
    if ((window as any).EBWidgets) {
      // Eventbrite script already loaded, init widgets
      loadEventbriteBossCourseButtonWidget(bossCourse, bookingCompleted);
    } else {
      // Likely initial app load happened on current page, wait for window load and
      // eventbrite script to finish loading on _app.ts
      window.addEventListener('load', (event) => {
        if ((window as any).EBWidgets) {
          // Eventbrite script loaded, init widgets
          loadEventbriteBossCourseButtonWidget(bossCourse, bookingCompleted);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function bookingCompleted(orderId: string) {
    analyticsEvent(BOOK_BOSS_COURSE_BOOKED, serializeBossCourseEventData(bossCourse));
    analyticsEvent(BOOK_BOSS_COURSE_BOOKED, serializeBossCourseEventData(bossCourse));
  }

  function onClickOpenBookingWidget(): void {
    analyticsEvent(BOOK_BOSS_COURSE_OPEN_MODAL, serializeBossCourseEventData(bossCourse));
  }

  function onClickMoreInfo(): void {
    analyticsEvent(BOOK_BOSS_COURSE_MORE_INFO_CLICKED, serializeBossCourseEventData(bossCourse));
  }

  return (
    <Box sx={cardStyle} data-test-id="boss-course-card">
      <Image
        src={bossCourse.image || '/images/boss_course_default.png'}
        width={800}
        height={400}
        objectFit="cover"
        alt={t('alt.bossCourseCard')}
        data-test-id="boss-course-card-image"
      />
      <Box p={6} pb={10}>
        <Heading as="h3" size="h-lg">
          {bossCourse.title}
        </Heading>
        <Text fontWeight={700} mb={1}>
          {bossCourse.dates}
        </Text>
        <Text mb={1}>{bossCourse.times}</Text>
        <Text mb={5}>{bossCourse.location}</Text>
        {bossCourse.priceDescription && (
          <Text fontWeight={648} mb={1}>
            {bossCourse.priceDescription}
          </Text>
        )}
        <Text>£{bossCourse.price.toString()}</Text>
        <Button
          id={`eventbrite-widget-modal-trigger-${bossCourse.eventId}`}
          onClick={() => onClickOpenBookingWidget()}
          display="block"
          mx="auto"
          my={5}
        >
          {t('bossCourseCard.bookButton')}
        </Button>
        <Link
          href={bossCourse.eventHref || '/'}
          fontWeight="600"
          target="_blank"
          p={3}
          onClick={onClickMoreInfo}
          data-test-id="boss-course-event-info-link"
        >
          {t('bossCourseCard.moreInfo')}
        </Link>
      </Box>
    </Box>
  );
}
