import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import { ROUTINE_POSITION } from '../../constants/enums';
import { IStoryblokRoutinePreviewCard } from '../../types/storyblokComponents';
import RoutinePreviewCard from '../cards/RoutinePreviewCard';

interface Props {
  blok: IStoryblokRoutinePreviewCard;
}

export default function StoryblokRoutinePreviewCard(props: Props) {
  const { blok } = props;

  return (
    <Box {...storyblokEditable(blok)} w="100%">
      <RoutinePreviewCard position={blok.position || ROUTINE_POSITION.CURRENT} />
    </Box>
  );
}
