import { Box } from '@chakra-ui/react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { SIZES } from '../../constants/enums';
import { IStoryblokTwoColumnPageSection } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokTwoColumnPageSection;
}

export default function StoryblokTwoColumnPageSection(props: Props) {
  const { blok } = props;

  const containerStyle = {
    display: { base: 'block', lg: 'flex' },
    flexDirection: 'row',
    '> div': {
      flex: 1,

      '&:first-of-type': {
        width: {
          base: '100%',
          lg:
            blok.column_one_desktop_width === SIZES.LG
              ? '66%'
              : blok.column_one_desktop_width === SIZES.SM
              ? '33%'
              : '50%',
        },
      },
    },
  } as const;

  return (
    <Box sx={containerStyle} {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.column_one[0]} key={blok.column_one[0]._uid} />
      <StoryblokComponent blok={blok.column_two[0]} key={blok.column_two[0]._uid} />
    </Box>
  );
}
