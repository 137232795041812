import * as Sentry from '@sentry/nextjs';
import { Dispatch, RefObject, SetStateAction, useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import {
  DocumentExtended,
  FullscreenFunctions,
  HTMLDivElementExtended,
  HTMLVideoElementExtended,
} from '../types/fullscreen';

export const defaultFullscreenFunctions: FullscreenFunctions = {
  element: 'fullscreenElement',
  enter: 'requestFullscreen',
  exit: 'exitFullscreen',
  change: 'fullscreenchange',
};

export const mozFullscreenFunctions: FullscreenFunctions = {
  element: 'mozFullScreenElement',
  enter: 'mozRequestFullScreen',
  exit: 'mozCancelFullscreen',
  change: 'mozfullscreenchange',
};

export const msFullscreenFunctions: FullscreenFunctions = {
  element: 'msFullscreenElement',
  enter: 'msRequestFullScreen',
  exit: 'msExitFullscreen',
  change: 'MSFullscreenChange',
};

export const webkitFullscreenFunctions: FullscreenFunctions = {
  element: 'webkitFullscreenElement',
  enter: 'webkitRequestFullScreen',
  exit: 'webkitCancelFullScreen',
  change: 'webkitfullscreenchange',
};

export default function useFullscreen(
  containerElementRef: RefObject<HTMLDivElement>,
  setVideoPlaying: Dispatch<SetStateAction<boolean>>,
  useVideoElementOnly: boolean,
): [boolean, Dispatch<SetStateAction<boolean>>, () => void, () => void] {
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [useNativeVideo, setIsVideoOnly] = useState<boolean>(isIOS || useVideoElementOnly);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isFullscreenFunctionsSet, setIsFullscreenFunctionsSet] = useState<boolean>(false);
  const [fullscreenFunctions, setFullscreenFunctions] = useState<FullscreenFunctions>(
    defaultFullscreenFunctions,
  );
  const [elementToFullscreen, setElementToFullscreen] = useState<
    HTMLVideoElementExtended | HTMLDivElementExtended
  >();

  useEffect(() => {
    if (!containerElementRef.current) return;

    if (!isFullscreenFunctionsSet && !useNativeVideo) {
      // Set the fullscreen functions and fullscreen element (as the parent <div/>, not the child <video/>)
      setElementToFullscreen(containerElementRef.current);
      // Set fullscreen functions based on the div element
      setFullscreenFunctions(getBrowserFullscreenFunctions(containerElementRef.current));
      setIsFullscreenFunctionsSet(true);
    }

    if (isVideoLoaded) {
      // Set the child video element
      const videoElement = containerElementRef.current.querySelector(
        'video',
      ) as HTMLVideoElementExtended;

      if (!videoElement) {
        // video element was not found, This could be due to an iframe being used, or an incorrect useVideoElement prop
        if (!isIOS) {
          // Fallback to using container element as fullscreen element
          setIsVideoOnly(false);
        } else {
          // iOS only allows fullscreen on video elements, so there's no fallback. elementToFullscreen will remain empty
          Sentry.captureException('fullscreen init error on iOS - no video element found.');
        }
        return;
      }

      if (useNativeVideo) {
        // Set fullscreen element (as the child <video/> element, not parent <div/>)
        setElementToFullscreen(videoElement);

        if (!isFullscreenFunctionsSet) {
          // Set fullscreen functions based on the video element
          setFullscreenFunctions(getBrowserFullscreenFunctions(videoElement));
          setIsFullscreenFunctionsSet(true);
        }
      }
    }
  }, [isVideoLoaded, containerElementRef, useNativeVideo, isFullscreenFunctionsSet]);

  // Get browser specific fullscreen functions. Different browsers even within e.g. webkit can use slightly different functions
  function getBrowserFullscreenFunctions(
    element: HTMLVideoElementExtended | HTMLDivElementExtended,
  ): FullscreenFunctions {
    if (typeof element.requestFullscreen !== 'undefined') {
      return defaultFullscreenFunctions;
    } else if (typeof element.msRequestFullScreen !== 'undefined') {
      return msFullscreenFunctions;
    } else if (typeof element.webkitRequestFullScreen !== 'undefined') {
      return webkitFullscreenFunctions;
    } else if (typeof element.mozRequestFullScreen !== 'undefined') {
      return mozFullscreenFunctions;
    } else {
      // If this error is thrown, check the browser version and add the fullscreen functions it supports
      Sentry.captureException('fullscreen on this element is not supported by browser');
      return defaultFullscreenFunctions;
    }
  }

  async function enterFullscreen() {
    if (!elementToFullscreen) {
      // Send error to sentry as a warning that a fullscreen element was not initiated correctly
      // One cause of this error is if fullscreen is requested on a div which does not contain a video on iOS.
      // This includes using fullscreen to open an image or div, or using iframes to load videos instead of a video element.
      // The above examples will work on all devices except iOS, which will fail gracefully by doing nothing
      Sentry.captureException('enterFullscreen error - no element found to fullscreen');
      return;
    }
    try {
      if (isIOS) {
        // Edge case for IOS where webkitRequestFullScreen and webkitfullscreenchange do not work
        elementToFullscreen['webkitEnterFullScreen']!(); // Call fullscreen on video
        elementToFullscreen.addEventListener(
          'webkitendfullscreen',
          function () {
            setVideoPlaying(false);
            setIsFullscreen(false);
          },
          false,
        );
      } else {
        elementToFullscreen[fullscreenFunctions.enter]!(); // Call fullscreen on element
        elementToFullscreen.addEventListener(
          fullscreenFunctions.change,
          function () {
            if ((document as DocumentExtended)[fullscreenFunctions.element] !== null) {
              setVideoPlaying(true);
              setIsFullscreen(true);
            } else {
              setVideoPlaying(false);
              setIsFullscreen(false);
            }
          },
          false,
        );
      }
      setVideoPlaying(true);
      setIsFullscreen(true);
    } catch (error) {
      Sentry.captureException(`enterFullscreen error - ${error}`);
    }
  }

  function exitFullscreen() {
    if (!elementToFullscreen) {
      Sentry.captureException('exitFullscreen error - no element found to fullscreen');
      return;
    }

    (document as DocumentExtended)[fullscreenFunctions.exit]!();
    setVideoPlaying(false);
    setIsFullscreen(false);
  }

  return [isFullscreen, setIsVideoLoaded, enterFullscreen, exitFullscreen];
}
