import { Box, Button, Flex, IconButton } from '@chakra-ui/react';
import { faLockAlt, faPlay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { ReactElement } from 'react';
import {
  AUTH_MODAL_PAGES,
  OVERLAYS,
  SPOTLIGHT,
  SUBSCRIBE_MODAL_PAGES,
} from '../../constants/enums';
import { VideoEvents, VIDEO_MODAL_OPENED } from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { openAuthModal, openSubscribeModal, openVideoModal } from '../../store/modalsSlice';
import { setVideoUrl } from '../../store/videoSlice';
import { backgroundImageContainerStyle } from '../../styles/global';
import analyticsEvent from '../../utils/logEvent';
import Spotlight from '../ui/Spotlight';

const overlayStyle = {
  backgroundImage: OVERLAYS.BOTTOM_LIGHT,
  width: '100%',
  height: '100%',
  position: 'absolute',
};

const lockButtonStyle = {
  position: 'absolute',
  top: 4,
  left: 4,
  background: 'blackAlpha.700',
};

interface Props {
  children: ReactElement;
  size?: 'lg';
  locked: boolean;
  name: string;
  previewImage: string;
  previewUrl?: string;
  fullUrl: string;
  isRoutine: boolean;
  spotlightOverlay?: boolean;
  analyticsEvents: VideoEvents;
  analyticsEventsData?: { [key: string]: string | number | Date | boolean } | null;
  previewAnalyticsEvents?: VideoEvents;
}

export default function VideoCard(props: Props) {
  const {
    children,
    size,
    locked,
    name,
    previewImage,
    previewUrl,
    fullUrl,
    isRoutine,
    spotlightOverlay = false,
    previewAnalyticsEvents,
    analyticsEvents,
    analyticsEventsData,
  } = props;
  const { user } = useTypedSelector((state) => state);
  const isLargeCard = size === 'lg';

  const cardHeightMobile = isLargeCard ? '26.25rem' : '17.5rem';
  const cardHeightDesktop = isLargeCard ? '31.25rem' : '17.5rem';
  const cardWidthMobile = isLargeCard ? '100vw' : '22.5rem';
  const cardWidthDesktop = isLargeCard ? '100%' : '25rem';

  const cardStyle = {
    position: 'relative',
    height: { base: cardHeightMobile, md: cardHeightDesktop },
    width: { base: cardWidthMobile, md: cardWidthDesktop },
    marginX: { base: isLargeCard ? '-1.25rem' : 'initial', md: 'inherit' },
    borderRadius: 4,
    overflow: 'hidden',
    zIndex: 1,
  } as const;

  const cardContentStyle = {
    padding: { base: 5, md: isLargeCard ? 10 : 6 },
    height: '100%',
    flexDirection: 'column',
    justifyContent: isRoutine ? 'flex-end' : 'center',
    marginTop: 'auto',
    textAlign: isRoutine ? 'left' : 'center',
  } as const;

  const buttonsFlexStyle = {
    marginTop: 2,
    gap: 2,
    justifyContent: isRoutine ? 'flex-start' : 'center',
  } as const;

  const t = useTranslations('Global');
  const dispatch: any = useAppDispatch();

  const playString = isRoutine && previewUrl ? 'videoCard.playTutorial' : 'videoCard.play';

  function onPlay(url: string | undefined): void {
    dispatch(openVideoModal());
    dispatch(setVideoUrl({ url: url || '' }));
    analyticsEvent(VIDEO_MODAL_OPENED, { origin: 'video_card', video_url: url });
  }

  return (
    <Box sx={cardStyle} data-test-id="video-card">
      <Box sx={backgroundImageContainerStyle}>
        <Image
          src={previewImage || 'https://a.storyblok.com/f/153574/411x280/7c74f99ff4/dance_bg_1.png'}
          layout="fill"
          objectFit="cover"
          objectPosition="top"
          quality={100}
          alt={`${t('alt.previewImage')} ${name}`}
          data-test-id="video-card-background-image"
        />
      </Box>
      {spotlightOverlay ? (
        <Spotlight
          option={SPOTLIGHT.STRONG}
          style={{
            width: '200%',
            top: '-120%',
            left: '-60%',
            opacity: 0.7,
            zIndex: -1,
          }}
        />
      ) : (
        <Box sx={overlayStyle} data-test-id="video-card-overlay" zIndex={-1} />
      )}

      {locked && (
        <IconButton
          sx={lockButtonStyle}
          icon={<FontAwesomeIcon icon={faLockAlt} />}
          onClick={() =>
            user.id
              ? dispatch(openSubscribeModal(SUBSCRIBE_MODAL_PAGES.DETAILS))
              : dispatch(openAuthModal(AUTH_MODAL_PAGES.SIGN_UP))
          }
          rounded="full"
          colorScheme="blackAlpha"
          aria-label={t('videoCard.lockButton')}
          data-test-id="video-card-locked-button"
        />
      )}
      <Flex sx={cardContentStyle}>
        <Box>
          {children}
          <Flex sx={buttonsFlexStyle}>
            {locked ? (
              <Button
                leftIcon={<FontAwesomeIcon icon={faLockAlt} />}
                onClick={() =>
                  user.id
                    ? dispatch(openSubscribeModal(SUBSCRIBE_MODAL_PAGES.DETAILS))
                    : dispatch(openAuthModal(AUTH_MODAL_PAGES.SIGN_UP))
                }
                data-test-id="routine-locked-button"
                size={isLargeCard ? 'lg' : 'xs'}
                variant="outline"
                colorScheme="white"
                mr={2}
              >
                {t(playString)}
              </Button>
            ) : (
              <Button
                leftIcon={<FontAwesomeIcon icon={faPlay} />}
                onClick={() => onPlay(fullUrl)}
                data-test-id="play-tutorial-button"
                size={isLargeCard ? 'lg' : 'xs'}
                variant="outline"
                colorScheme="white"
                mr={2}
              >
                {t(playString)}
              </Button>
            )}
            {previewUrl && (
              <Button
                onClick={() => onPlay(previewUrl)}
                data-test-id="play-preview-button"
                leftIcon={<FontAwesomeIcon icon={faPlay} />}
                size={isLargeCard ? 'lg' : 'xs'}
                variant="unstyled"
                colorScheme="white"
              >
                {t('videoCard.playPreview')}
              </Button>
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
