import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { SOCIAL_MEDIA } from '../../constants/enums';
import { Choreographer } from '../../types/choreographers';
import SocialMediaLink from './SocialMediaLink';

const socialLinksStyle = {
  marginTop: { base: 0, sm: 3, md: 6 },
} as const;

interface Props {
  choreographer: Choreographer;
}

export default function ChoreographerContent(props: Props) {
  const { choreographer } = props;

  const t = useTranslations('Global');

  return (
    <Box data-test-id="choreographer-content-container">
      <Heading as="h3" size="h-2xl" mb={3}>
        {choreographer.name}
      </Heading>
      <Text fontSize="lg">{choreographer.description}</Text>
      {choreographer.credits && (
        <Text>
          <strong>
            {t('credits')}: {choreographer.credits}
          </strong>
        </Text>
      )}
      <HStack sx={socialLinksStyle} spacing={3} ml={-1}>
        {choreographer.instagramUrl && (
          <SocialMediaLink href={choreographer.instagramUrl} socialMedia={SOCIAL_MEDIA.INSTAGRAM} />
        )}
        {choreographer.facebookUrl && (
          <SocialMediaLink href={choreographer.facebookUrl} socialMedia={SOCIAL_MEDIA.FACEBOOK} />
        )}
        {choreographer.tiktokUrl && (
          <SocialMediaLink href={choreographer.tiktokUrl} socialMedia={SOCIAL_MEDIA.TIKTOK} />
        )}
        {choreographer.youtubeUrl && (
          <SocialMediaLink href={choreographer.youtubeUrl} socialMedia={SOCIAL_MEDIA.YOUTUBE} />
        )}
      </HStack>
    </Box>
  );
}
