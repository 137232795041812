import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { SPOTLIGHT } from '../../constants/enums';
import { PROFILE_MODAL_CLOSED } from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { clearModalsSlice } from '../../store/modalsSlice';
import analyticsEvent from '../../utils/logEvent';
import UserProfileForm from '../forms/UserProfileForm';
import Spotlight from '../ui/Spotlight';

export default function ProfileModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals } = useTypedSelector((state) => state);
  const dispatch: any = useAppDispatch();
  const t = useTranslations('Global.profileModal');

  useEffect(() => {
    if (modals.profileModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.profileModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    analyticsEvent(PROFILE_MODAL_CLOSED);
    dispatch(clearModalsSlice());
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="5xl">
      <ModalOverlay />
      <ModalContent data-test-id="profile-modal-content" overflow="hidden">
        <Spotlight
          option={SPOTLIGHT.RIGHT_2}
          style={{
            width: { base: '100%', md: '90%', lg: '100%' },
            left: { base: '40%', md: '45%', lg: '40%' },
            top: { md: '30%' },
          }}
        />
        <ModalCloseButton data-test-id="profile-modal-close-button" />
        <Heading>{t('heading')}</Heading>
        <ModalBody mt={6}>
          <UserProfileForm closeModal={closeModal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
