import { Box, Button, Text } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import { ROUTINE_POSITION } from '../../constants/enums';
import {
  currentRoutinePreviewEvents,
  nextRoutinePreviewEvents,
  previousRoutinePreviewEvents,
} from '../../constants/events';
import { BACKSTAGE_URL } from '../../constants/variables';
import { useTypedSelector } from '../../hooks/store';
import { useGetRoutinesCurrentSetQuery } from '../../store/api';
import { formatRoutineDateRange } from '../../utils/dateTime';
import { serializeIrlRoutineEventData } from '../../utils/serialize/analyticsEventsData';
import VideoCard from './VideoCard';

const buttonStyle = {
  position: 'absolute',
  top: { base: 5, md: 10 },
  left: { base: 5, md: 10 },
} as const;
interface Props {
  position: ROUTINE_POSITION;
}

export default function RoutinePreviewCard(props: Props) {
  const { position } = props;
  const t = useTranslations('Global');

  const { routines } = useTypedSelector((state) => state);

  const { data, isFetching, isLoading, isError } = useGetRoutinesCurrentSetQuery(
    (position === ROUTINE_POSITION.CURRENT && !!routines.irlCurrent) ||
      (position === ROUTINE_POSITION.NEXT && !!routines.irlNext) ||
      (position === ROUTINE_POSITION.PREVIOUS && !!routines.irlPrevious)
      ? skipToken
      : undefined,
  );

  const routine =
    position === ROUTINE_POSITION.CURRENT
      ? routines.irlCurrent
      : position === ROUTINE_POSITION.NEXT
      ? routines.irlNext
      : routines.irlPrevious;

  const analyticsEvents =
    position === ROUTINE_POSITION.CURRENT
      ? currentRoutinePreviewEvents
      : position === ROUTINE_POSITION.NEXT
      ? nextRoutinePreviewEvents
      : previousRoutinePreviewEvents;

  const routineLabel =
    position === ROUTINE_POSITION.CURRENT
      ? t('routineLabels.current')
      : position === ROUTINE_POSITION.NEXT
      ? t('routineLabels.next')
      : t('routineLabels.previous');

  if (!routine) return <></>;

  return (
    <VideoCard
      size="lg"
      locked={false}
      name={`${routine.artist} - ${routine.songName}`}
      previewImage={routine.previewImage}
      fullUrl={routine.previewVideo}
      isRoutine={true}
      analyticsEvents={analyticsEvents}
      analyticsEventsData={{ ...serializeIrlRoutineEventData(routine) }}
    >
      <Box>
        <NextLink href={`${BACKSTAGE_URL}/vip-videos`} passHref>
          <Button sx={buttonStyle} colorScheme="gray" data-test-id="routine-label-button">
            {routineLabel}
          </Button>
        </NextLink>
        <Text fontSize="lg" fontWeight={600} textTransform="uppercase" mb={0} lineHeight={8}>
          {formatRoutineDateRange(routine.startDate, routine.endDate)}
        </Text>
        <Text fontSize="3xl" fontWeight={800} mb={0} lineHeight={8}>
          {routine.artist.toUpperCase()}
        </Text>
        <Text fontSize="3xl" mb={0} lineHeight={8}>
          {routine.songName}
        </Text>
        <Text textTransform="uppercase">
          {t('by')} {routine.choreographer.name}
        </Text>
      </Box>
    </VideoCard>
  );
}
