import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import Image from 'next/image';
import { getAlignedMarginStyle, getImageSizeStyle } from '../../styles/global';
import { IStoryblokCustomImage } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokCustomImage;
}

export default function StoryblokImage(props: Props) {
  const { blok } = props;

  if (!blok.image || !blok.image.filename) return <></>;

  const imageContainerStyle = {
    ...getImageSizeStyle(blok.size),
    ...(blok.horizontal_alignment && getAlignedMarginStyle(blok.horizontal_alignment)),
    position: 'relative',

    '> span': {
      position: 'unset !important',
    },

    img: {
      objectFit: 'contain',
      width: '100% !important',
      position: 'relative !important',
      height: 'unset !important',
    },
  } as const;

  return (
    <Box sx={imageContainerStyle} {...storyblokEditable(blok)} data-test-id="image-container">
      <Image
        src={blok.image.filename}
        alt={blok.image.alt}
        layout="fill"
        objectFit="contain"
        sizes={
          typeof imageContainerStyle.width !== 'string'
            ? `${imageContainerStyle.width.md}`
            : imageContainerStyle.width
        }
      ></Image>
    </Box>
  );
}
