import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import { BossCourse } from '../../types/bossCourses';
import { IStoryblokBossCourseCard } from '../../types/storyblokComponents';
import BossCourseCard from '../cards/BossCourseCard';

interface Props {
  blok: IStoryblokBossCourseCard;
}

export default function StoryblokBossCourseCard(props: Props) {
  const { blok } = props;

  // Serialize storyblok boss course card fields to camelCase
  const bossCourse: BossCourse = {
    title: blok.title,
    eventId: blok.event_id,
    eventHref: blok.event_href.url,
    image: blok.image.filename,
    dates: blok.dates,
    times: blok.times,
    location: blok.location,
    priceDescription: blok.price_description,
    price: parseInt(blok.price),
  };

  return (
    <Box {...storyblokEditable(blok)}>
      <BossCourseCard bossCourse={bossCourse} />
    </Box>
  );
}
