import { Box, Container, Heading } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { COLOURS } from '../../constants/enums';
import logoBlack from '../../public/brand/logo.svg';
import logoWhite from '../../public/brand/logo_white.svg';
import { getAlignedContainerStyle, getVerticalAlignmentStyle } from '../../styles/global';
import { containerPaddingYmd } from '../../styles/theme/components';
import { IStoryblokHero } from '../../types/storyblokComponents';
import StoryblokSectionBackground from './StoryblokSectionBackground';

const logoContainerStyle = {
  position: 'relative',
  width: { base: 20, lg: 24 },
  height: { base: 20, lg: 24 },
  marginBottom: 2,
} as const;

interface Props {
  blok: IStoryblokHero;
}

export default function StoryblokHero(props: Props) {
  const { blok } = props;

  const t = useTranslations('Global');

  const backgroundStyle = {
    justifyContent: getVerticalAlignmentStyle(blok.vertical_alignment),
    minHeight: {
      base: `calc(100vh - ${containerPaddingYmd.base} - 4.25rem)`,
      md: `calc(100vh - ${containerPaddingYmd.md} - 4.5rem)`,
      xl: `calc(100vh - ${containerPaddingYmd.xl} - 4.5rem)`,
      '2xl': `calc(100vh - ${containerPaddingYmd['2xl']} - 4.5rem)`,
    },
  } as const;

  const logo =
    !blok.background_image.filename &&
    !blok.background_video.url &&
    blok.background_colour === COLOURS.WHITE
      ? logoBlack
      : logoWhite;

  return (
    <StoryblokSectionBackground blok={blok} styles={backgroundStyle}>
      <Container
        sx={getAlignedContainerStyle('column', blok.horizontal_alignment, blok.vertical_alignment)}
        size="md"
        data-test-id="container"
      >
        {blok.include_logo && (
          <Box sx={logoContainerStyle}>
            <Image
              src={logo}
              layout="fill"
              objectFit="contain"
              alt={t('alt.logo')}
              data-test-id={`logo-${logo === logoBlack ? 'black' : 'white'}`}
            />
          </Box>
        )}
        <Heading as="h1" size="h-3xl" maxW={1000} m="auto">
          {blok.title}
        </Heading>
      </Container>
    </StoryblokSectionBackground>
  );
}
