import { Box, Heading, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { ReactNode } from 'react';
import {
  MARK_LINK,
  MARK_STYLED,
  NODE_HEADING,
  NODE_PARAGRAPH,
  RenderOptions,
} from 'storyblok-rich-text-react-renderer';
import { BACKSTAGE_URL } from '../constants/variables';
import { headingSizes } from '../styles/headings';

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadingSize = 'h-sm' | 'h-md' | 'h-lg' | 'h-xl' | 'h-2xl' | 'h-3xl' | 'h-4xl';

export const RichTextConfig: RenderOptions = {
  blokResolvers: {},
  nodeResolvers: {
    [NODE_PARAGRAPH]: (children: ReactNode | null) => {
      return <Text>{children}</Text>;
    },
    [NODE_HEADING]: (children: ReactNode | null, props: { level: number }) => {
      const { level } = props;

      let tag: HeadingTag = 'h3';
      let size: HeadingSize = 'h-2xl';

      if (level === 1) {
        tag = 'h1';
        size = 'h-4xl';
      }
      if (level === 2) {
        tag = 'h2';
        size = 'h-3xl';
      }
      if (level === 3) {
        tag = 'h3';
        size = 'h-2xl';
      }
      if (level === 4) {
        tag = 'h4';
        size = 'h-xl';
      }
      if (level === 5) {
        tag = 'h5';
        size = 'h-lg';
      }
      if (level === 6) {
        tag = 'h6';
        size = 'h-md';
      }

      if (children && (children as any)[0].props) {
        // override size with css class set in richtext
        size = (children as any)[0].props.className;
      }

      return (
        <Heading as={tag} size={size}>
          {children}
        </Heading>
      );
    },
  },
  markResolvers: {
    [MARK_STYLED]: (children: ReactNode, props: { class?: string | undefined }) => {
      const style = props.class;

      // override size with css class set in richtext
      if (style) {
        if (style.includes('h-')) {
          const castStyle = style as HeadingSize;
          // use custom header size
          return (
            <Box as="span" sx={headingSizes[castStyle]}>
              {children}
            </Box>
          );
        } else {
          // use standard text size
          return (
            <Box as="span" fontSize={style}>
              {children}
            </Box>
          );
        }
      }

      return <>{children}</>;
    },
    [MARK_LINK]: (children: any, props: any) => {
      const { href, target, linktype } = props;

      if (linktype === 'email') {
        return <Link href={`mailto:${href}`}>{children}</Link>;
      }

      if (href.includes('/backstage') && BACKSTAGE_URL) {
        href.replace('/backstage', BACKSTAGE_URL);
      }

      const targetValue = target || href?.match(/^(https?:)?\/\//) ? '_blank' : '_self';

      // Internal links: map to <Link>
      if (href)
        return (
          <NextLink href={href} passHref>
            <Link target={targetValue}>{children}</Link>
          </NextLink>
        );
      else return <Text>{children}</Text>;
    },
  },
};
