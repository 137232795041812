import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import { EXTENDED_SIZES } from '../../constants/enums';
import { IStoryblokSpacer } from '../../types/storyblokComponents';

interface Props {
  blok: IStoryblokSpacer;
}

export default function StoryblokSpacer(props: Props) {
  const { blok } = props;

  const spacerStyle = {
    width: '100%',
    height:
      blok.size === EXTENDED_SIZES.XS
        ? { base: 4, md: 6 }
        : blok.size === EXTENDED_SIZES.SM
        ? { base: 8, md: 16 }
        : blok.size === EXTENDED_SIZES.MD
        ? { base: 14, md: 32 }
        : blok.size === EXTENDED_SIZES.LG
        ? { base: 32, md: 52 }
        : { base: 52, md: 80 },
  } as const;

  return <Box sx={spacerStyle} {...storyblokEditable(blok)} data-test-id="spacer" />;
}
