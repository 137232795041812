import { Box } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { SPOTLIGHT } from '../../constants/enums';
import center_1 from '../../public/spotlights/center_1.svg';
import center_2 from '../../public/spotlights/center_2.svg';
import left_1 from '../../public/spotlights/left_1.svg';
import left_2 from '../../public/spotlights/left_2.svg';
import right_1 from '../../public/spotlights/right_1.svg';
import right_2 from '../../public/spotlights/right_2.svg';
import strong from '../../public/spotlights/strong.svg';

interface SpotlightOption {
  option: SPOTLIGHT;
  image: string;
  width?: { base: string; md: string; lg: string } | string;
  right?: { base: string; md: string; lg: string } | string;
  left?: { base: string; md: string; lg: string } | string;
  top?: { base: string; md: string; lg: string } | string;
  bottom?: { base: string; md: string; lg: string } | string;
}

const spotlightOptions: Array<SpotlightOption> = [
  {
    option: SPOTLIGHT.CENTER_1,
    image: center_1,
    width: { base: '90vw', md: '80vw', lg: '70vw' },
  },
  {
    option: SPOTLIGHT.CENTER_2,
    image: center_2,
    width: { base: '100%', md: '80%', lg: '70%' },
  },
  {
    option: SPOTLIGHT.LEFT_1,
    image: left_1,
    width: { base: '120vw', md: '100vw', lg: '90vw' },
    left: { base: '-45vw', md: '-30vw', lg: '-20vw' },
    bottom: { base: '-60vw', md: '-50vw', lg: '-35vw' },
  },
  {
    option: SPOTLIGHT.LEFT_2,
    image: left_2,
    width: { base: '120vw', md: '100vw', lg: '70vw' },
    left: { base: '-35vw', md: '-30vw', lg: '-25vw' },
    top: { base: '-20vw', md: '-15vw', lg: '-15vw' },
  },
  {
    option: SPOTLIGHT.RIGHT_1,
    image: right_1,
    width: { base: '100vw', md: '90vw', lg: '80vw' },
    right: { base: '-50vw', md: '-45vw', lg: '-40vw' },
    top: { base: '-30vw', md: '-25vw', lg: '-20vw' },
  },
  {
    option: SPOTLIGHT.RIGHT_2,
    image: right_2,
    width: { base: '100vw', md: '90vw', lg: '90vw' },
    right: { base: '-35vw', md: '-40vw', lg: '-45vw' },
    bottom: { base: '-50vw', md: '-45vw', lg: '-35vw' },
  },
  {
    option: SPOTLIGHT.STRONG,
    image: strong,
    width: { base: '100vw', md: '90vw', lg: '90vw' },
  },
];

interface Props {
  option: SPOTLIGHT;
  style?: any;
}

export default function Spotlight(props: Props) {
  const { option, style } = props;
  const t = useTranslations('Global');

  const styles =
    spotlightOptions.find((spotlightOption) => spotlightOption.option === option) ||
    spotlightOptions[0];

  const spotlightContainerStyle = {
    position: 'absolute',
    width: styles.width,
    left: styles.left,
    right: styles.right,
    top: styles.top,
    bottom: styles.bottom,
    ...style,
  };

  return (
    <Box sx={spotlightContainerStyle}>
      <Image
        src={styles.image}
        quality={100}
        alt={t('alt.spotlight')}
        data-test-id={`spotlight-${option}`}
      />
    </Box>
  );
}
