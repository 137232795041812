import { Container } from '@chakra-ui/react';
import { StoryblokComponent } from '@storyblok/react';
import { getAlignedContainerStyle, getVerticalAlignmentStyle } from '../../styles/global';
import { IStoryblokPageSection } from '../../types/storyblokComponents';
import StoryblokSectionBackground from './StoryblokSectionBackground';

interface Props {
  blok: IStoryblokPageSection;
}

export default function StoryblokPageSection(props: Props) {
  const { blok } = props;

  if (!blok) return <></>;

  return (
    <StoryblokSectionBackground
      blok={blok}
      styles={{ justifyContent: getVerticalAlignmentStyle(blok.vertical_alignment) }}
    >
      <Container
        sx={getAlignedContainerStyle('column', blok.horizontal_alignment, blok.vertical_alignment)}
        size="md"
        data-test-id="container"
      >
        {blok.blocks?.map((blok) => (
          <StoryblokComponent blok={blok} key={blok._uid} />
        ))}
      </Container>
    </StoryblokSectionBackground>
  );
}
