import { variantGhost, variantLink, variantOutline, variantSolid } from '../buttons';
import { headingSizes } from '../headings';

export const containerPaddingX = { base: '1.25rem', md: '2.5rem', '2xl': '5rem', '3xl': '8rem' };
export const containerPaddingYsm = { base: '2.5rem', xl: '3.5rem', '2xl': '4rem' };
export const containerPaddingYmd = { base: '2.5rem', md: '4rem', xl: '5rem', '2xl': '6rem' };
export const containerMaxWidth = { base: '84rem', '2xl': '96rem', '3xl': '110rem' };

const floatingLabelStyle = {
  transform: 'scale(0.85) translateY(-32px)',
  paddingLeft: 0,
};

export const components = {
  Heading: {
    baseStyle: {
      maxWidth: '70rem',
      ...headingSizes['h-2xl'],
    },
    sizes: headingSizes,
  },
  Text: {
    baseStyle: {
      maxWidth: '60rem',
    },
    sizes: headingSizes,
  },
  Container: {
    sizes: {
      sm: {
        maxWidth: containerMaxWidth,
        paddingX: containerPaddingX,
        paddingY: containerPaddingYsm,
      },
      md: {
        maxWidth: containerMaxWidth,
        paddingX: containerPaddingX,
        paddingY: containerPaddingYmd,
      },
      lg: {
        maxWidth: '100%',
        padding: 0,
      },
    },
  },
  Button: {
    baseStyle: {
      textTransform: 'uppercase',
    },
    variants: {
      solid: variantSolid,
      outline: variantOutline,
      ghost: variantGhost,
      link: variantLink,
    },
    sizes: {
      lg: {
        fontSize: { base: '1.075rem', '2xl': '1.125rem' },
        height: { base: '2.75rem', '2xl': '3rem' },
      },
      xs: {
        textTransform: 'none',
        height: { base: '1.5rem', sm: '1.625rem', '2xl': '1.5rem' },
      },
    },
    defaultProps: {
      colorScheme: 'pink',
    },
  },
  Form: {
    variants: {
      // Custom floating label field - as documented here https://chakra-ui.com/community/recipes/floating-labels
      floating: {
        container: {
          marginY: 8,
          '.chakra-input__left-element': {
            left: 2,
            top: '0.625rem',
            zIndex: 1,
          },
          '.chakra-input__left-element ~ input': {
            paddingLeft: 10,
          },
          '.chakra-input__left-element ~ label': {
            paddingLeft: 10,
          },
          input: {
            borderBottom: '0.0625rem solid',
            borderColor: 'gray.400',

            _focus: {
              borderColor: 'gray.700',
              boxShadow: 'none',
            },
          },
          label: {
            top: 0,
            left: 0,
            position: 'absolute',
            pointerEvents: 'none',
            marginX: 0,
            paddingX: 1,
            marginY: 2,
            transformOrigin: 'left top',
            fontWeight: 400,

            '&.active': {
              ...floatingLabelStyle,
              marginX: 0,
            },
          },
          _focusWithin: {
            label: {
              ...floatingLabelStyle,
            },
          },

          '.react-datepicker-wrapper:has(input) + label': {
            ...floatingLabelStyle,
          },
          'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label': {
            ...floatingLabelStyle,
          },
        },
      },
    },
  },
  Input: {
    variants: {
      filled: () => ({
        field: {
          color: 'black',
          borderRadius: 0,
          _placeholder: {
            color: 'gray.900',
          },
        },
      }),
      flushed: () => ({
        field: {
          '+ label': {
            marginX: 0,
          },
        },
      }),
    },
  },
  Divider: {
    sizes: {
      sm: {
        borderBottomWidth: 2,
        marginY: 2,
      },
      md: {
        borderBottomWidth: 4,
        marginY: 4,
      },
      lg: {
        borderBottomWidth: 6,
        marginY: 8,
      },
    },
    baseStyle: {
      borderColor: 'gray.300',
    },
    defaultProps: {
      size: 'md',
    },
  },
  Drawer: {
    baseStyle: {
      dialog: {
        color: 'black',
      },
    },
  },
  Modal: {
    baseStyle: {
      dialog: {
        margin: 'auto !important',
        color: 'black',
      },
    },
    sizes: {
      '5xl': {
        dialog: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: 20,
          paddingBottom: 12,
          width: { base: 'calc(100% - 2rem)', lg: 'inherit' },
        },
        body: {
          maxWidth: '34rem',
          marginBottom: 4,
          textAlign: 'center',
        },
        closeButton: {
          top: 8,
          right: 8,
        },
        footer: {
          paddingY: 6,
          width: { base: '100%', md: '75%' },
          justifyContent: 'center',
        },
      },
    },
  },
  Tabs: {
    baseStyle: {
      tablist: {
        marginBottom: { base: 4, lg: 8 },
        justifyContent: 'center !important',
        border: 'none !important',
      },
      tab: {
        fontWeight: '700',
        marginBottom: '-0.125rem !important',
        marginX: 1,
        borderBottom: '0.25rem solid transparent',
        animation: 'fadeIn 1s',

        '&:last-child': {
          marginBottom: '-0.125rem !important',
        },

        '&[aria-selected=true]': {
          color: '#FF002E !important',
          borderBottom: '0.25rem solid',
        },

        '&:active': {
          background: 'none !important',
        },
      },
      tabpanel: {
        animation: 'fadeIn 1s',
      },
    },
  },
};
