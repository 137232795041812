import { Box, Button, HStack, Slide, Text, useBreakpointValue } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useCookies } from 'react-cookie';
import { firebaseApp } from '../../config/firebase';
import { COOKIES_ACCEPTED, COOKIE_MODAL_OPENED } from '../../constants/events';
import useIsBackstageRoute from '../../hooks/isBackstageRoute';
import { useAppDispatch } from '../../hooks/store';
import { setAuthModalViewed, updateCookies } from '../../store/cookiesSlice';
import { openCookieModal } from '../../store/modalsSlice';
import { addDaysToToday } from '../../utils/dateTime';
import analyticsEvent from '../../utils/logEvent';

const containerStyle = {
  display: 'flex',
  position: 'sticky',
  flexDirection: { base: 'column', md: 'row' },
  color: 'black',
  zIndex: 10,
  backgroundColor: 'white',
  padding: 5,
  marginX: 8,
  rounded: 'lg',
  mb: 4,
};

const textStyle = {
  fontSize: 'sm',
  maxW: 'unset',
  mb: { base: 4, md: 0 },
  mr: { base: 0, md: 4 },
};

export default function CookieBanner() {
  const t = useTranslations('Global');
  const dispatch: any = useAppDispatch();
  const [cookies, setCookie] = useCookies(['authModalViewed', 'cookie_settings']);
  const isBackstageRoute = useIsBackstageRoute();

  const isMobile = useBreakpointValue({ base: true, md: false });

  function onClickOpenCookieSettings(): void {
    analyticsEvent(COOKIE_MODAL_OPENED, { origin_component: 'cookie_banner' });
    dispatch(openCookieModal());
  }

  function onAcceptCookies(): void {
    analyticsEvent(COOKIES_ACCEPTED);
    const settings: any = {
      path: '/',
    };
    if (window.location.host.includes('sos.dance')) {
      settings['domain'] = '.sos.dance';
    }
    setCookie(
      'cookie_settings',
      { necessary: true, performance: true, analytics: true, marketing: true },
      settings,
    );
    // Set authModalViewed cookie with expiry of 1 week from now
    dispatch(
      updateCookies({
        performanceAccepted: true,
        analyticsAccepted: true,
        marketingAccepted: true,
        settingsSet: true,
      }),
    );

    // Enable GA
    firebaseApp.automaticDataCollectionEnabled = true;

    if (isBackstageRoute) {
      const expires = addDaysToToday(7);
      setCookie('authModalViewed', true, { path: '/', expires: expires });
      dispatch(setAuthModalViewed());
    }
  }

  return (
    <Slide direction="bottom" in={true} style={{ zIndex: 10 }}>
      <Box sx={containerStyle} data-test-id="cookie-banner">
        <Text sx={textStyle}>{t('cookieBanner.description')}</Text>
        <HStack justify="right">
          <Button
            size={isMobile ? 'sm' : 'md'}
            variant="outline"
            onClick={() => onClickOpenCookieSettings()}
            data-test-id="cookie-banner-settings-button"
          >
            {t('cookieBanner.settingsButton')}
          </Button>
          <Button
            size={isMobile ? 'sm' : 'md'}
            onClick={() => onAcceptCookies()}
            data-test-id="cookie-banner-accept-button"
          >
            {t('cookieBanner.acceptButton')}
          </Button>
        </HStack>
      </Box>
    </Slide>
  );
}
