import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { colours } from './theme/colours';
import { components } from './theme/components';
import { fonts } from './theme/fonts';
import { styles } from './theme/styles';

const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'black',
  }),
  {
    styles: styles,
    colors: colours,
    fonts: fonts,
    breakpoints: {
      '3xl': '2560px',
    },
    components: components,
  },
);

export default theme;
