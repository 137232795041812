import { Box } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import { IStoryblokChoreographerCarousel } from '../../types/storyblokComponents';
import ChoreographerCarousel from '../carousels/ChoreographerCarousel';

interface Props {
  blok: IStoryblokChoreographerCarousel;
}

export default function StoryblokChoreographerCarousel(props: Props) {
  const { blok } = props;

  return (
    <Box {...storyblokEditable(blok)}>
      <ChoreographerCarousel backgroundColor={blok.background_colour} />
    </Box>
  );
}
